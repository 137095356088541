import styled, { css } from "styled-components";

import { MIN_SILVER, MIN_GOLD, MIN_PLATINUM, MIN_DIAMOND } from "../../../../constants/mediaquerys";

interface IProps {
    shouldScaleLogo: boolean;
    isFixedBackground: boolean;
}

const positioningStyle = css`
    position: absolute;
    top: 50%;
    left: 0;
`;

const positioningStyleAndAnimation = css<IProps>`
    ${positioningStyle}
    background-color: var(--back-1);
    transition: transform 0.3s linear, background-color 0.3s linear;
    transform-origin: top left;

    /* ${({ shouldScaleLogo }) =>
        shouldScaleLogo &&
        css`
            background-color: transparent;
        `} */
`;

const Default = css<IProps>`
    ${positioningStyleAndAnimation}

    ${({ isFixedBackground, shouldScaleLogo }) =>
        !isFixedBackground
            ? css`
                  ${shouldScaleLogo
                      ? css`
                            transform: scale(0.45) translate(-24px, -50%);
                        `
                      : css`
                            transform: translate(-24px, -30%);

                            @media (min-width: 500px) {
                                transform: translate(-24px, -22%);
                            }

                            @media (min-width: 650px) {
                                transform: translate(-24px, -18%);
                            }
                        `};
              `
            : css`
                  transform-origin: center left;
                  transform: translateY(-12px);

                  ${shouldScaleLogo &&
                  css`
                      transform: scale(0.45) translate(0, calc(-100%));
                  `}
              `}
`;

const Silver = css<IProps>`
    transform: translate(-24px, -22%);
    ${positioningStyleAndAnimation}

    ${({ shouldScaleLogo }) =>
        shouldScaleLogo &&
        css`
            transform: scale(0.33333333333) translate(-24px, -50%);
        `};
`;

const Gold = css<IProps>`
    ${({ shouldScaleLogo }) =>
        shouldScaleLogo
            ? css`
                  transform: scale(0.33333333333) translate(-24px, -50%);
              `
            : css`
                  transform: translate(-24px, -18%);
              `};
`;

const Platinum = css<IProps>`
    ${({ shouldScaleLogo }) =>
        shouldScaleLogo
            ? css`
                  transform: scale(0.33333333333) translate(-76px, -50%);
              `
            : css`
                  transform: translate(-76px, -20%);
              `};
`;

const Diamond = css``;

export const SHeaderLogoWrapper = styled.div<IProps>`
    ${Default};
    ${MIN_SILVER`${Silver}`};
    ${MIN_GOLD`${Gold}`};
    ${MIN_PLATINUM`${Platinum}`};
    ${MIN_DIAMOND`${Diamond}`};
`;
