import styled, { css } from "styled-components";

import { MIN_SILVER, MIN_GOLD, MIN_PLATINUM, MIN_DIAMOND } from "../../../../constants/mediaquerys";

const Default = css`
    min-height: 120px;
    padding: 12px;
`;

const Silver = css`
`;

const Gold = css``;

const Platinum = css`
    padding: 18px;
    min-height: 140px;
`;

const Diamond = css``;

export const SPlanningDateModalTextarea = styled.textarea`
    ${Default};
    ${MIN_SILVER`${Silver}`};
    ${MIN_GOLD`${Gold}`};
    ${MIN_PLATINUM`${Platinum}`};
    ${MIN_DIAMOND`${Diamond}`};
`;
