import styled, { css } from "styled-components";

import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../../constants/mediaquerys";

const Default = css`
    display: flex;
    flex-direction: column;
    gap: 18px;

    > * {
      width: 100%;
    }
`;

const Silver = css`
flex-direction: row;
gap: 0;
justify-content: space-between;
align-items: center;
`;

const Gold = css``;

const Platinum = css`
flex-direction: column;
gap: 19px;
`;

const Diamond = css``;

export const SPlanningDateModalSubmitWrapper = styled.div`
  ${Default};
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
