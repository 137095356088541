import styled, { css } from "styled-components";

import { MIN_SILVER, MIN_GOLD, MIN_PLATINUM, MIN_DIAMOND } from "../../../../constants/mediaquerys";

interface SHeaderProps {}

const Default = css<SHeaderProps>`
    width: 100%;
`;

const Silver = css<SHeaderProps>``;

const Gold = css``;

const Platinum = css<SHeaderProps>``;

const Diamond = css``;

export const SHeader = styled.header<SHeaderProps>`
    ${Default};
    ${MIN_SILVER`${Silver}`};
    ${MIN_GOLD`${Gold}`};
    ${MIN_PLATINUM`${Platinum}`};
    ${MIN_DIAMOND`${Diamond}`};
`;
