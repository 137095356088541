import Link from "next/link";
import React, { AnchorHTMLAttributes, FunctionComponent } from "react";
import { FlattenSimpleInterpolation } from "styled-components";

import SLink from "./SLink";

export type IProps = AnchorHTMLAttributes<HTMLAnchorElement> & {
  overwriteStyle: FlattenSimpleInterpolation;
  internal?: boolean;
}; 

export const IconLink: FunctionComponent<IProps> = ({
  overwriteStyle, children, internal, href, ...props 
}) => internal ? 
  <SLink overwriteStyle={overwriteStyle} href={href} {...props}>{children}</SLink> : 
  <Link href={href} passHref><SLink overwriteStyle={overwriteStyle} {...props}>{children}</SLink></Link>;
