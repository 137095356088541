import styled, { css } from "styled-components";

import { MIN_SILVER, MIN_GOLD, MIN_PLATINUM, MIN_DIAMOND } from "../../../../../constants/mediaquerys";

interface IProps {}

const Default = css<IProps>`
    width: 100%;
    position: relative;
    z-index: 3;
`;

const Silver = css<IProps>`
    margin-left: auto;
    margin-bottom: 48px
`;

const Gold = css``;

const Platinum = css<IProps>`
margin-bottom: 76px;
`;

const Diamond = css``;

export const SSubPageList = styled.ul`
    ${Default};
    ${MIN_SILVER`${Silver}`};
    ${MIN_GOLD`${Gold}`};
    ${MIN_PLATINUM`${Platinum}`};
    ${MIN_DIAMOND`${Diamond}`};
`;
