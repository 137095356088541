import styled, { css } from "styled-components";

import { MIN_SILVER, MIN_GOLD, MIN_PLATINUM, MIN_DIAMOND } from "../../../../constants/mediaquerys";
import { H1_1366_Regular, H1_360_Regular, H1_768_Regular, H4_1366, H4_360, H4_768 } from "src/templates/t27/constants/typography";

const Default = css`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center; 
    color: #4bb543;

    h1 {
        ${H1_360_Regular}
    }

    h4{
        ${H4_360}
    }
`;

const Silver = css`
    h1 {
        ${H1_768_Regular}
    }

    h4{
        ${H4_768}
    }
`;

const Gold = css``;

const Platinum = css`
    h1 {
        ${H1_1366_Regular}
    }

    h4{
        ${H4_1366}
    }
`;

const Diamond = css``;

export const SPlanningDateModalSuccessMessage = styled.div`
    ${Default}
    ${MIN_SILVER`${Silver}`};
    ${MIN_GOLD`${Gold}`};
    ${MIN_PLATINUM`${Platinum}`};
    ${MIN_DIAMOND`${Diamond}`};
`;
