import { IContext, Context, ILandingEvent, RichText } from "src/common";
import { handleFormSubmit } from "src/common/utils/formSubmit";
import { richToPlainText } from "src/common/utils/text/plaintext";
import ChevronUpIcon from "src/templates/t27/icons/ChevronUpIcon";

import React, { Fragment, FunctionComponent, useContext, useState, useEffect } from "react";
import { useForm } from "react-hook-form/dist/react-hook-form.ie11";

import { SPlanningDateModalOverlay } from "./styles/SPlanningDateModalOverlay";
import { SPlanningDateModal } from "./styles/SPlanningDateModal";
import { SPlanningDateModalWrapper } from "./styles/SPlanningDateModalWrapper";
import { SPlanningDateModalContentArea } from "./styles/SPlanningDateModalContentArea";
import { SPlanningDateModalContentHeader } from "./styles/SPlanningDateModalContentHeader";
import { SPlanningDateModalForm } from "./styles/SPlanningDateModalForm";
import { SPlanningDateModalInput } from "./styles/SPlanningDateModalInput";
import { SPlanningDateModalTextarea } from "./styles/SPlanningDateModalTextarea";
import { SPlanningDateModalSelect } from "./styles/SPlanningDateModalSelect";
import { SPlanningDateModalSubmitWrapper } from "./styles/SPlanningDateModalSubmitWrapper";
import { SPlanningDateModalButton } from "./styles/SPlanningDateModalButton";
import { SPlanningDateModalCheckboxLabel } from "./styles/SPlanningDateModalCheckboxLabel";
import { SPlanningDateModalTopBar } from "./styles/SPlanningDateModalTopBar";
import { SPlanningDateModalExpandIconWrapper } from "./styles/SPlanningDateModalExpandIconWrapper";
import CloseIcon from "src/templates/t27/icons/CloseIcon";
import { SPlanningDateModalSuccessMessage } from "./styles/SPlanningDateModalSuccessMessage";
import { SPlanningDateModalIconsWrapper } from "./styles/SPlanningDateModalIconsWrapper";
import { SPlanningDateModalCloseIconWrapper } from "./styles/SPlanningDateModalCloseIconWrapper";

const positionTimeoutDurationWithMs = 750;

const modalHeight = {
    bronzeHeight: 100,
    silverHeight: 80,
    platinumHeight: 120,
} as const;

export type TModalHeight = typeof modalHeight;

const PlanningDateModal: FunctionComponent = () => {
    const props = useContext(Context);
    const [hidePlanningDateModal, setHidePlanningDateModal] = useState(false);
    const [isHiddenByTime, setIsHiddenByTime] = useState(false);
    const [hiddenByTimeTimeoutId, setHiddenByTimeTimeoutId] = useState(null); // Store the timeout
    const [isLoaded, setIsLoaded] = useState(false);

    const [isExpanded, setIsExpanded] = useState(false);
    const [position, setPosition] = useState<"fixed" | "initial">("fixed");
    const [hasPrivacyChecked, setHasPrivacyChecked] = useState(false);

    useEffect(() => {
        const storedIsHidden = sessionStorage.getItem("hidePlanningDateModal");
        if (storedIsHidden !== null) {
            setHidePlanningDateModal(JSON.parse(storedIsHidden));
        } else {
            sessionStorage.setItem("hidePlanningDateModal", JSON.stringify(hidePlanningDateModal));
        }
    }, []);

    useEffect(() => {
        sessionStorage.setItem("hidePlanningDateModal", JSON.stringify(hidePlanningDateModal));
    }, [hidePlanningDateModal]);

    useEffect(() => {
        if (!isExpanded) {
            const positionTimeoutId = setTimeout(() => {
                setPosition("initial");
            }, positionTimeoutDurationWithMs + 50);

            return () => {
                clearTimeout(positionTimeoutId);
            };
        } else {
            setPosition("fixed");
        }
    }, [isExpanded]);

    useEffect(() => {
        if (typeof window !== "undefined") {
            if (isExpanded) {
                document.body.style.overflow = "hidden";
            }

            return () => {
                document.body.style.overflow = "";
            };
        }
    }, [isExpanded]);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            const ele = document.getElementById("PlanningDateModal");
            const hasStayClass = ele?.classList?.contains("stay");
            if (!hasStayClass) {
                setIsHiddenByTime(true);
            }
        }, 6000);

        const timeoutId2 = setTimeout(() => {
            setIsLoaded(true);
        }, 1000);

        setHiddenByTimeTimeoutId(timeoutId); // Store the timeout identifier in a state variable

        return () => {
            clearTimeout(timeoutId); // Clear the timeout when the component unmounts
            clearTimeout(timeoutId2);
        };
    }, []);

    const { register, handleSubmit, errors, reset } = useForm();
    const [success, setSuccess] = React.useState(false);

    const { Settings } = props;
    if ("deaktiviert" === Settings?.appointmentRequestDisplay) {
        return null;
    }

    const universalTexts = props?.UniversalTexts;
    const customPlanningDateText = universalTexts?.planningDateText;

    const planningDateContent = {
        headline:
            universalTexts?.planningDateHeadline ||
            "Jetzt gleich einen Planungstermin für Ihre neue Küche vereinbaren!",
        text: "Nur einen Klick von Ihrer neuen Küche entfernt! Starten Sie jetzt Ihre persönliche und unverbindliche Planungsterminanfrage. Wir melden uns bei Ihnen so schnell wie möglich, um Ihre Planungsterminanfrage zu bestätigen. Damit wir Ihren Planungstermin bestätigen können, tragen Sie bitte Ihre Telefonnummer und E-Mail Adresse ein.",
    };

    const handleExpand = () => {
        setIsExpanded(!isExpanded);
        clearTimeout(hiddenByTimeTimeoutId);
    };

    const onSubmit = (data): void => {
        // Kontaktformular: d-26922068a30343d98a725c3fdaa663da
        // Terminanfrage: d-deb56cdeb9c34309be7a1a521bda38e6
        // Reparaturanfrage: d-82dc22fc5dee48db9e177c0140b37441
        // Produktanfrage: d-ee2129a87d2d4e70bbcf21f1bff6491e
        let title: string | undefined;
        if (typeof window !== "undefined") {
            title = document.title;
        }

        handleFormSubmit({
            ...data,
            subject: `Planungsterminanfrage ${data?.Name ? `von ${data?.Name} ` : ""}${title ? `- ${title}` : ""}`,
            dealerMail: props?.CompanyInformationPublic?.email,
            line: props?.CompanyInformationPrivate?.line,
            templateId: "d-deb56cdeb9c34309be7a1a521bda38e6",
            // cc: [{ email: "serviceseite@gedk.de" }]
        }).then((r) => {
            reset();
            setSuccess(true);
        });
    };

    const timeOptions: React.ReactElement[] = [];

    for (let i = 6; i < 20; i++) {
        let value;
        let value2;

        if (i < 10) {
            value = `0${i}:00`;
            value2 = `0${i}:30`;
        } else {
            value = `${i}:00`;
            value2 = `${i}:30`;
        }

        timeOptions.push(
            <Fragment key={i}>
                <option value={value}>{value}</option>
                <option value={value2}>{value2}</option>
            </Fragment>
        );
    }

    const vorname = "Name";
    const datum = "Datum";
    const uhrzeit = "Uhrzeit";
    const email = universalTexts?.eMail || "E-Mail";
    const telefon = "Telefon";
    const kommentar = "Kommentar";

    if (hidePlanningDateModal || isHiddenByTime) {
        return null;
    }

    return (
        <SPlanningDateModalOverlay
            id="PlanningDateModal"
            positionTimeoutDurationWithMs={positionTimeoutDurationWithMs}
            isExpanded={isExpanded}
            style={{ position }}
        >
            <SPlanningDateModal>
                <SPlanningDateModalWrapper
                    positionTimeoutDurationWithMs={positionTimeoutDurationWithMs}
                    isExpanded={isExpanded}
                >
                    <SPlanningDateModalContentArea
                        positionTimeoutDurationWithMs={positionTimeoutDurationWithMs}
                        isExpanded={isExpanded}
                        modalHeight={modalHeight}
                    >
                        <SPlanningDateModalContentHeader>
                            {customPlanningDateText && richToPlainText(customPlanningDateText) !== "" ? (
                                <RichText fragment textContent={customPlanningDateText} />
                            ) : (
                                <p>{planningDateContent?.text}</p>
                            )}
                        </SPlanningDateModalContentHeader>
                        {success ? (
                            <SPlanningDateModalSuccessMessage>
                                <h1>DANKE!</h1>
                                <h4>Wir werden uns sobald wie möglich bei Ihnen melden.</h4>
                            </SPlanningDateModalSuccessMessage>
                        ) : (
                            <SPlanningDateModalForm onSubmit={handleSubmit(onSubmit)}>
                                <SPlanningDateModalInput
                                    required={true}
                                    type="text"
                                    placeholder={vorname}
                                    id={"vorname"}
                                    name="name"
                                    ref={register}
                                />
                                <SPlanningDateModalInput
                                    required={true}
                                    type="email"
                                    placeholder={email}
                                    id={"email"}
                                    name="email"
                                    ref={register}
                                />
                                <div className="date_time">
                                    <SPlanningDateModalInput
                                        required={true}
                                        type="date"
                                        placeholder={datum}
                                        id={"datum"}
                                        name="datum"
                                        ref={register}
                                    />
                                    <SPlanningDateModalSelect
                                        name={"uhrzeitVon"}
                                        placeholder={uhrzeit}
                                        id={"uhrzeitVon"}
                                        ref={register}
                                    >
                                        {timeOptions}
                                    </SPlanningDateModalSelect>
                                </div>
                                <SPlanningDateModalInput
                                    required={true}
                                    type="text"
                                    placeholder={telefon}
                                    id={"tel"}
                                    name="tel"
                                    ref={register}
                                />
                                <SPlanningDateModalTextarea
                                    rows={2}
                                    required={true}
                                    name="kommentar"
                                    id={"kommentar"}
                                    placeholder={kommentar}
                                    ref={register}
                                />
                                <SPlanningDateModalSubmitWrapper>
                                    <SPlanningDateModalCheckboxLabel htmlFor={"checkbox"} isChecked={hasPrivacyChecked}>
                                        <span className="checkbox">
                                            <CloseIcon />
                                            <SPlanningDateModalInput
                                                required={true}
                                                type="checkbox"
                                                placeholder="Ich akzeptiere die Datenschutzerklärung"
                                                name="checkbox"
                                                id="checkbox"
                                                onChange={(e) => setHasPrivacyChecked(e.target.checked)}
                                                checked={hasPrivacyChecked}
                                                ref={register({ required: true })}
                                            />
                                        </span>
                                        <span className="checkbox-content">
                                            Ich akzeptiere die
                                            <a
                                                target="_blank"
                                                rel="noreferrer"
                                                href={"/imprint-datenschutz#Datenschutz"}
                                            >
                                                Datenschutzerklärung
                                            </a>
                                        </span>
                                    </SPlanningDateModalCheckboxLabel>
                                    <SPlanningDateModalButton type="submit">
                                        {universalTexts?.Submit || "Absenden"}
                                    </SPlanningDateModalButton>
                                </SPlanningDateModalSubmitWrapper>
                            </SPlanningDateModalForm>
                        )}
                    </SPlanningDateModalContentArea>
                    <SPlanningDateModalTopBar
                        modalHeight={modalHeight}
                        isLoaded={isLoaded}
                        positionTimeoutDurationWithMs={positionTimeoutDurationWithMs}
                    >
                        <h2 onClick={handleExpand}>{planningDateContent?.headline}</h2>
                        <SPlanningDateModalIconsWrapper>
                            <SPlanningDateModalExpandIconWrapper
                                positionTimeoutDurationWithMs={positionTimeoutDurationWithMs}
                                isExpanded={isExpanded}
                                onClick={handleExpand}
                            >
                                <ChevronUpIcon />
                            </SPlanningDateModalExpandIconWrapper>
                            <SPlanningDateModalCloseIconWrapper onClick={() => setHidePlanningDateModal(true)}>
                                <CloseIcon />
                            </SPlanningDateModalCloseIconWrapper>
                        </SPlanningDateModalIconsWrapper>
                    </SPlanningDateModalTopBar>
                </SPlanningDateModalWrapper>
            </SPlanningDateModal>
        </SPlanningDateModalOverlay>
    );
};

export default PlanningDateModal;
