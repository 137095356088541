import styled, { css } from "styled-components";

import { MIN_SILVER, MIN_GOLD, MIN_PLATINUM, MIN_DIAMOND } from "../../../../constants/mediaquerys";
import { H3_1366, H3_360, H3_768 } from "src/templates/t27/constants/typography";
import { TModalHeight } from "../PlanningDateModal";

interface IProps {
    modalHeight: TModalHeight;
    positionTimeoutDurationWithMs: number;
    isLoaded: boolean;
}

const Default = css<IProps>`
    padding: 16px 23px;
    background-color: var(--primary-100);
    color: var(--back-1);
    position: relative;
    z-index: 100;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: transform ${({ positionTimeoutDurationWithMs }) => positionTimeoutDurationWithMs}ms ease-in-out;
    transform-origin: bottom;
    transform: translateY(100%);

    ${({ modalHeight: { bronzeHeight }, isLoaded }) =>
        css`
            height: ${bronzeHeight ?? 80}px;
            transform: ${isLoaded && "translateY(0)"};
        `}

    h2 {
        ${H3_360}
        text-overflow: ellipsis;
        overflow: hidden;
        cursor: pointer;
    }
`;

const Silver = css<IProps>`
    ${({ modalHeight: { silverHeight } }) => css`
        height: ${silverHeight ?? 60}px;
    `}

    h2 {
        ${H3_768}
    }
`;

const Gold = css``;

const Platinum = css<IProps>`
    padding: 30px 32px;
    ${({ modalHeight: { platinumHeight } }) => css`
        height: ${platinumHeight ?? 120}px;
    `}

    h2 {
        ${H3_1366}
    }
`;

const Diamond = css``;

export const SPlanningDateModalTopBar = styled.div`
    ${Default};
    ${MIN_SILVER`${Silver}`};
    ${MIN_GOLD`${Gold}`};
    ${MIN_PLATINUM`${Platinum}`};
    ${MIN_DIAMOND`${Diamond}`};
`;
