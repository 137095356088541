import styled, { css } from "styled-components";

import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../../constants/mediaquerys";

const Default = css`
display: flex;
justify-content: center;
align-items: center;
padding: 48px 0;

img {
  width: 100%;
  max-height: 77px;
  object-fit: contain; 
  object-position: left;
}
`;

const Silver = css`
justify-content: flex-start;

img {
  max-height: 88px;
}
`;

const Gold = css``;

const Platinum = css`
padding: 76px 0;

img {
  max-height: 128px;
}
`;

const Diamond = css``;

export const SFooterLogoWrapper = styled.div`
  ${Default};
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;

