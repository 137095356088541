import { C_768_Bold, C_360_Bold, H4_1366 } from "src/templates/t27/constants/typography";

import styled, { css } from "styled-components";

import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../../../constants/mediaquerys";

const Default = css`
  padding-top: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  > h4 {
    ${C_360_Bold}
  }
`;

const Silver = css`
> h4 {
  ${C_768_Bold}
}
`;

const Gold = css``;

const Platinum = css`
padding-top: 24px;

> h4 {
  ${H4_1366}
}
`;

const Diamond = css``;

export const SModalHeader = styled.div`
  ${Default};
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
