import styled, { css } from "styled-components";

import { MIN_SILVER, MIN_GOLD, MIN_PLATINUM, MIN_DIAMOND } from "../../../../constants/mediaquerys";

interface IProps {
    isFixedBackground: boolean;
}

const Default = css`
    /* width: 100%;
    pointer-events: none; */
`;

const Silver = css<IProps>`
    padding-top: 69px;

    ${({ isFixedBackground }) => css`
        ${isFixedBackground &&
        css`
            position: absolute;
            padding-top: 0;
            width: 100%;
            z-index: 3;
            top: 69px;
            ul {
                background-color: var(--back-1);
                padding-top: 2px;
            }
        `};
        .sub-page-list {
            max-width: ${isFixedBackground ? css`calc(37.5% - 12px)` : css`calc(50% - 12px)`};

            ${!isFixedBackground &&
            css`
                li {
                    padding-left: 1px;
                }
            `};
        }
    `};
`;

const Gold = css``;

const Platinum = css<IProps>`
    padding-top: 101px;

    ${({ isFixedBackground }) => css`
        ${isFixedBackground &&
        css`
            padding-top: 0;
            top: 101px;
        `};

        .sub-page-list {
            max-width: ${isFixedBackground ? css`calc(37.5% - 19px)` : css`calc(50% - 19px)`};
        }
    `};
`;

const Diamond = css``;

export const SSubPageFixedBackgroundWrapper = styled.div<IProps>`
    ${Default};
    ${MIN_SILVER`${Silver}`};
    ${MIN_GOLD`${Gold}`};
    ${MIN_PLATINUM`${Platinum}`};
    ${MIN_DIAMOND`${Diamond}`};
`;
