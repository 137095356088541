import styled, { css } from "styled-components";

import { MIN_SILVER, MIN_GOLD, MIN_PLATINUM, MIN_DIAMOND } from "../../../../constants/mediaquerys";
import {
    C_1366_Regular,
    C_360_Regular,
    C_768_Regular,
    C_Link_1366,
    C_Link_360,
    C_Link_768,
} from "src/templates/t27/constants/typography";
import { CSSWithOpacityHoverAnimation } from "src/templates/t27/constants/globalStyle";

const Default = css<{ isChecked: boolean }>`
    width: 100%;
    display: flex;
    align-items: center;
    gap: 12px;

    .checkbox {
        position: relative;
        background-color: var(--back-3);
        width: 48px;
        min-width: 48px;
        height: 48px;
        cursor: pointer;

        input[type="checkbox"] {
            width: 100%;
            height: 100%;
            visibility: hidden;
        }

        svg {
            position: absolute;
            top: 0;
            left: 0;
            width: 50%;
            height: 50%;
            transform: translate(50%, 50%);
            transition: opacity 0.3s ease;
            opacity: ${({ isChecked }) => (isChecked ? 1 : 0)};
        }

        &-content {
            color: var(--front-2);
            ${C_360_Regular}

            a {
                color: var(--primary-100);
                ${C_Link_360}

                ${CSSWithOpacityHoverAnimation}
            }
        }
    }
`;

const Silver = css`
    .checkbox {
        &-content {
            ${C_768_Regular}

            a {
                ${C_Link_768}
            }
        }
    }
`;

const Gold = css``;

const Platinum = css`
    gap: 19px;

    .checkbox {
        width: 76px;
        min-width: 76px;
        height: 76px;

        &-content {
            ${C_1366_Regular}

            a {
                ${C_Link_1366}
            }
        }
    }
`;

const Diamond = css``;

export const SPlanningDateModalCheckboxLabel = styled.label`
    ${Default};
    ${MIN_SILVER`${Silver}`};
    ${MIN_GOLD`${Gold}`};
    ${MIN_PLATINUM`${Platinum}`};
    ${MIN_DIAMOND`${Diamond}`};
`;
