import { BREAKPOINTS_DM } from "src/templates/t27/constants/mediaquerys";

import styled, { css } from "styled-components";

interface IProps {
    shouldScale: boolean;
    isFixedBackground: boolean;
}

export const SMenu = styled.div<IProps>`
    margin-left: auto;
    width: 25px;
    height: 20px;
    position: relative;
    cursor: pointer;
    transition: transform 0.3s linear;
    ${({ shouldScale, isFixedBackground }) =>
        !shouldScale &&
        isFixedBackground &&
        css`
            transform: translateY(36px);
        `}

    @media (${BREAKPOINTS_DM.silver_768}) {
        transform: unset;
    }

    @media (${BREAKPOINTS_DM.platinum_1366}) {
        width: 30px;
        height: 23px;
    }
`;

const line2 = css`
    width: 100%;
    height: 1.5px;
    position: absolute;
    background-color: var(--front-2);

    @media (${BREAKPOINTS_DM.platinum_1366}) {
        height: 2px;
    }
`;

export const Line: any = styled.div`
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: background-color ease 0.2s 0.15s;
    ${line2};

    &:before,
    &:after {
        content: "";
        ${line2};
    }

    &:before {
        top: -10px;
    }

    &:after {
        top: 10px;
    }
`;
