import React, { FunctionComponent } from "react";

import ContainerT27 from "../container/ContainerT27";
import { SSectionSeparator } from "./styles/SSectionSeparator";
import { FlattenSimpleInterpolation } from "styled-components";

const SectionSeparator: FunctionComponent<{
    hasContainerPaddingWrapper?: boolean;
    stylesOverwrite?: FlattenSimpleInterpolation;
}> = ({ hasContainerPaddingWrapper = true, stylesOverwrite }) =>
    hasContainerPaddingWrapper ? (
        <ContainerT27>
            <SSectionSeparator stylesOverwrite={stylesOverwrite} />
        </ContainerT27>
    ) : (
        <SSectionSeparator stylesOverwrite={stylesOverwrite} />
    );

export default SectionSeparator;
