import { IContext, Context } from "src/common";
import EmailIcon from "src/common/icons/EmailIcon";
import FacebookIcon from "src/common/icons/FacebookIcon";
import MapPinIcon from "src/common/icons/MapPinIcon";
import PhoneIcon from "src/common/icons/PhoneIcon";
import { addHttpsToLinks } from "src/common/utils/addHttpsToLinks";

import { faInstagram, faYoutube, faTiktok, faWhatsapp, faTwitter, faPinterest } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FunctionComponent, useContext } from "react";
import { FlattenSimpleInterpolation } from "styled-components";

import { IconLink } from "./IconLink/IconLink";

const SocialIcons: FunctionComponent<{overwriteStyle?: FlattenSimpleInterpolation}> = ({ overwriteStyle }) => 
{
  const props = useContext<IContext>(Context);
  const companyInformation = props?.CompanyInformationPublic;

  const items = [
    {
      className: "phone",
      href: companyInformation?.phone && `tel:${companyInformation?.phone}`,
    },
    {
      className: "mail",
      href: companyInformation?.email && `mailto:${companyInformation?.email}`,
    },
    {
      className: "address",
      href: props?.PageContact?.slug && `/${props?.PageContact?.slug}`,
    },
    {
      className: "fb",
      target: "_blank",
      href:
        companyInformation?.facebook &&
        addHttpsToLinks(companyInformation?.facebook),
    },
    {
      className: "insta",
      target: "_blank",
      href:
        companyInformation?.instagram &&
        addHttpsToLinks(companyInformation?.instagram),
    },
    {
      className: "youtube",
      target: "_blank",
      href:
        companyInformation?.youtube &&
        addHttpsToLinks(companyInformation?.youtube),
    },
    {
      className: "twitter",
      target: "_blank",
      href:
        companyInformation?.twitter &&
        addHttpsToLinks(companyInformation?.twitter),
    },
    {
      className: "pintrest",
      target: "_blank",
      href:
        companyInformation?.pintrest &&
        addHttpsToLinks(companyInformation?.pintrest),
    },
    {
      className: "whatsapp",
      target: "_blank",
      href:
        companyInformation?.whatsapp &&
        addHttpsToLinks(companyInformation?.whatsapp),
    },
    {
      className: "tiktok",
      target: "_blank",
      href:
        companyInformation?.tiktok &&
        addHttpsToLinks(companyInformation?.tiktok),
    },
  ].filter((item) => item.href);

  return (
    <>
      {items.map(({ className, href, target }, index) => (
        <IconLink
          key={index}
          href={href}
          className={className + (target === "_blank" ? " boxicon" : "")}
          target={target}
          overwriteStyle={overwriteStyle}
        >
          { className === "phone" && <PhoneIcon/>}
          { className === "mail" && <EmailIcon />}
          { className === "address" && <MapPinIcon />}
          { className === "fb" && <FacebookIcon />}
          { className === "insta" && <FontAwesomeIcon icon={faInstagram} /> }
          { className === "youtube" && <FontAwesomeIcon icon={faYoutube} /> }
          { className === "twitter" && <FontAwesomeIcon icon={faTwitter} /> }
          { className === "pintrest" && <FontAwesomeIcon icon={faPinterest} /> }
          { className === "whatsapp" && <FontAwesomeIcon icon={faWhatsapp} /> }
          { className === "tiktok" && <FontAwesomeIcon icon={faTiktok} /> }
        </IconLink>
      ))}
    </>
  );
};

export default SocialIcons;
