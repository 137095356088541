import styled, { css } from "styled-components";

import { MIN_SILVER, MIN_GOLD, MIN_PLATINUM, MIN_DIAMOND } from "../../../../constants/mediaquerys";
import { P_1366_Bold, P_360_Bold, P_768_Bold } from "src/templates/t27/constants/typography";
import { CSSWithOpacityHoverAnimation } from "src/templates/t27/constants/globalStyle";

const Default = css`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--front-1);
    color: var(--back-1);
    padding: 12px;

    ${P_360_Bold}

    ${CSSWithOpacityHoverAnimation}
`;

const Silver = css`
    padding: 10.5px 22px;

    ${P_768_Bold}
`;

const Gold = css``;

const Platinum = css`
    padding: 18px;
    ${P_1366_Bold}
`;

const Diamond = css``;

export const SPlanningDateModalButton = styled.button`
    ${Default};
    ${MIN_SILVER`${Silver}`};
    ${MIN_GOLD`${Gold}`};
    ${MIN_PLATINUM`${Platinum}`};
    ${MIN_DIAMOND`${Diamond}`};
`;
