import styled, { FlattenInterpolation, ThemeProps, css } from "styled-components";

import { MIN_SILVER, MIN_GOLD, MIN_PLATINUM, MIN_DIAMOND } from "../../../../constants/mediaquerys";

interface IProps {
    positionTimeoutDurationWithMs: number;
    isExpanded: boolean;
}

type IStyle = FlattenInterpolation<ThemeProps<IProps>>;

const Default = ({ positionTimeoutDurationWithMs, isExpanded }: IProps): IStyle => css`
    position: fixed;
    bottom: 0px;
    width: calc(100% - 48px); // 48px - padding left and right
    left: 24px; // 48 / 2
    transform: none;
    z-index: 101;
    transition: all ${positionTimeoutDurationWithMs}ms;
    ${isExpanded
        ? css`
              overflow-x: hidden;
          `
        : css`
              overflow: hidden;
          `}
`;

const Silver = css``;

const Gold = css``;

const Platinum = css`
    width: 700px;
    left: calc(50% - 360px); // 588 / 2
`;

const Diamond = css``;

export const SPlanningDateModalWrapper = styled.div`
    ${Default};
    ${MIN_SILVER`${Silver}`};
    ${MIN_GOLD`${Gold}`};
    ${MIN_PLATINUM`${Platinum}`};
    ${MIN_DIAMOND`${Diamond}`};
`;
