import styled, { FlattenInterpolation, ThemeProps, css } from "styled-components";

import { MIN_SILVER, MIN_GOLD, MIN_PLATINUM, MIN_DIAMOND } from "../../../../constants/mediaquerys";
import { CSSWithOpacityHoverAnimation } from "src/templates/t27/constants/globalStyle";

interface IProps {
    isExpanded?: boolean;
    positionTimeoutDurationWithMs: number;
}

type IStyle = FlattenInterpolation<ThemeProps<IProps>>;

const Default = ({ isExpanded, positionTimeoutDurationWithMs }: IProps): IStyle => css`
    cursor: pointer;
    &, > svg {
        width: 20px;
        height: 18px;
    }

    ${isExpanded &&
    css`
        transform: rotate(180deg);
    `}

    ${CSSWithOpacityHoverAnimation}
    transition: transform ${positionTimeoutDurationWithMs}ms ease-in-out;
`;

const Silver = css``;

const Gold = css``;

const Platinum = css`
&, > svg {
    width: 24px;
    height: 22px;
}
`;

const Diamond = css``;

export const SPlanningDateModalExpandIconWrapper = styled.span`
    ${Default};
    ${MIN_SILVER`${Silver}`};
    ${MIN_GOLD`${Gold}`};
    ${MIN_PLATINUM`${Platinum}`};
    ${MIN_DIAMOND`${Diamond}`};
`;
