import { useEffect, useState } from "react";

interface IUseShouldScaleLogo {
    /** default = 68 */
    scrollYThreshHold?: number;
}

export const useShouldScale = ({ scrollYThreshHold = 68 }: IUseShouldScaleLogo = {}) => {
    const [shouldScale, setShouldScale] = useState(false);

    useEffect(() => {
        if (typeof window !== "undefined") {
            const getData = () => {
                const windowScrolledPixels = window.scrollY;
                if (windowScrolledPixels > scrollYThreshHold) {
                    setShouldScale(true);
                } else {
                    setShouldScale(false);
                }
            };

            window.addEventListener("scroll", getData);

            return () => {
                window.removeEventListener("scroll", getData);
            };
        }
    }, []);

    return { shouldScale };
};
