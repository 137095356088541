import React, { FC, useContext, useState } from "react";
import { Context, IContext } from "src/common";
import Affix from "src/common/components/Affix";

import { Container } from "../Grid";
import Map from "../Map";
import PlanningDateModal, {
  PlanningDateModalContext,
} from "../PlanningDateModal";
import RichTextGeneral from "../RichText";
import {
  AffixContainer,
  HaderContainer,
  LogoLink,
  Logo,
  HeaderBanner,
  HeaderBannerDropDown,
} from "./Header.styles";
import Navigation, { BurguerIcon } from "./Navigation";
const googleApiKey =
  process.env.NEXT_PUBLIC_MAPSAPIKEY || process.env.MAPSAPIKEY;

const Header: FC<{ fixed?: boolean }> = ({ fixed }) => {
  const [isMenuActive, toggleMenuActive] = useState(false);
  const modal = useContext(PlanningDateModalContext);

  const props = useContext<IContext>(Context);
  const companyLocation = props.PageContact && {
    lat: props.PageContact?.location.latitude,
    lng: props.PageContact?.location.longitude,
  };

  const companyInformation = props.CompanyInformationPublic;
  const logo = companyInformation?.logo?.[0];
  const email = companyInformation?.email;
  const phone = companyInformation?.phone;

  return (
    <>
      <AffixContainer>
        <Affix offset={20} fixed={fixed}>
          <HeaderBanner>
            <Container>
              <div>
                <a href={`tel:${phone}`}>
                  <i className="fa fa-fw fa-phone" /> {phone}
                </a>
                <a href={`mailto:${email}`}>
                  <i className="fa fa-fw fa-envelope" /> {email}
                </a>
              </div>

              <div className="left-side">
                <HeaderBannerDropDown>
                  <a>Öffnungszeiten</a>
                  <div>
                    <RichTextGeneral
                      textContent={companyInformation.openingHours}
                      fragment
                    />
                    <p>Weitere Termine nach</p>
                    <p>
                      <a onClick={() => modal.setShowModal(true)}>
                        Vereinbarung
                      </a>
                    </p>
                  </div>
                </HeaderBannerDropDown>

                <HeaderBannerDropDown flex>
                  <a>Anfahrt</a>
                  <div>
                    <img
                      src={`https://maps.googleapis.com/maps/api/staticmap?center=${companyLocation?.lat},${companyLocation?.lng}&size=190x130&zoom=10&maptype=roadmap&markers=color:red%7C${companyLocation?.lat},${companyLocation?.lng}&key=${googleApiKey}`}
                    />
                    <address>
                      {companyInformation?.managingDirector}
                      <br />
                      {companyInformation?.street}
                      <br />
                      {companyInformation?.postcode + " "}{" "}
                      {companyInformation?.place}
                      <br />
                    </address>
                  </div>
                </HeaderBannerDropDown>
                <HeaderBannerDropDown>
                  <a href="/kontakt">Kontakt</a>
                </HeaderBannerDropDown>
              </div>
            </Container>
          </HeaderBanner>
          <HaderContainer>
            <Container>

            {logo && (
              <LogoLink href="/">
                <Logo src={logo.src} />
              </LogoLink>
            )}
            <BurguerIcon
              open={isMenuActive}
              onClick={() => toggleMenuActive(!isMenuActive)}
            />
            <Navigation
              items={props.PrimaryNavigation?.items}
              slug={props.slug}
              isMenuActive={isMenuActive}
            />
            </Container>

          </HaderContainer>
        </Affix>
      </AffixContainer>

      {modal.showModal && <PlanningDateModal />}
    </>
  );
};

export default Header;
