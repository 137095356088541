import styled, { css } from "styled-components";

import { MIN_SILVER, MIN_GOLD, MIN_PLATINUM, MIN_DIAMOND } from "../../../../constants/mediaquerys";
import { P_1366_Regular, P_360_Regular, P_768_Regular } from "src/templates/t27/constants/typography";

const Default = css`
    display: flex;
    gap: 18px;
    align-items: center;
    /* justify-content: center; */
    flex-wrap: wrap;
    > * {
        flex-basis: 100%;
    }

    .date_time {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 18px;
        > * {
            flex-basis: 100%;
        }
    }

    input,
    textarea,
    select {
        width: 100%;
        border: none;
        background-color: var(--back-3);
        ${P_360_Regular}
        transition: opacity 0.3s ease;

        &:hover,
        &:focus {
            opacity: 0.85;
        }
    }
`;

const Silver = css`
    input,
    textarea,
    select {
        ${P_768_Regular}
    }

    > input, .date_time {
        flex-basis: 48.7%;
    }
`;

const Gold = css``;

const Platinum = css`
    input,
    textarea,
    select {
        ${P_1366_Regular}
    }

    > input, .date_time {
        flex-basis: 100%;
    }
`;

const Diamond = css``;

export const SPlanningDateModalForm = styled.form`
    ${Default};
    ${MIN_SILVER`${Silver}`};
    ${MIN_GOLD`${Gold}`};
    ${MIN_PLATINUM`${Platinum}`};
    ${MIN_DIAMOND`${Diamond}`};
`;
