import React, { FunctionComponent } from "react";

const ChevronUpIcon: FunctionComponent<{size?: number}> = ({ size = 20 }) => 
  (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 28 17"
      fill="none"
    >
      <path d="M26 15L14.2553 3L2 15" stroke="currentColor" strokeWidth="3" />
    </svg>
  );

export default ChevronUpIcon;
