import React, { FunctionComponent, useContext } from "react";
import { Context, LogoBannerHorizontal } from "src/common";
import { getContactCompanyNameShort } from "../../../../../common/components/ImprintStart";

import { Section, Container } from "../Grid";
import Address from "./Address";
import { FooterWrapper, Bottom } from "./Footer.style";
import Navigation from "./Navigation";
import OpeningHours from "./OpeningHours";
interface IFooterLinkInColumnList {
  title: string;
  link: string;
  isBold: boolean;
}

const Footer: FunctionComponent<{ brands?: boolean }> = () => {
  const props = useContext(Context);
  const companyInformation = props.CompanyInformationPublic;

  return (
    <Section>
      <FooterWrapper>
        <Container>
          <OpeningHours companyInformation={companyInformation} />
          <Navigation />
          <Address companyInformation={companyInformation}/>
        </Container>
      </FooterWrapper>
      <Bottom>
        <Container>
          <span>Copyright ® {new Date().getFullYear()} - {getContactCompanyNameShort()}</span>
          <a href="/imprint-datenschutz">Impressum</a>
          <a href="/imprint-datenschutz#dataprivacy">Datenschutzerklärung</a>
        </Container>
      </Bottom>
    </Section>
  );
};

export default Footer;
