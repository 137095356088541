import React, { FunctionComponent } from "react";

const FacebookIcon: FunctionComponent<{size?: number}> = ({ size= 20 }) => 
  (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 37 36" fill="none">
      <path d="M20.0793 35.9934H0.958984V0C1.12086 0 1.28273 0.0190391 1.44461 0.0190391C13.0996 0.0190391 24.7546 0.0190391 36.4096 0.0190391C36.5715 0.0190391 36.7334 0.0190391 36.9333 0.0190391V0.542614C36.9333 12.1945 36.9333 23.8465 36.9333 35.4984C36.9333 35.6602 36.9524 35.8221 36.9524 35.9839H25.8401C25.8401 31.5287 25.8401 27.0831 25.8401 22.628C25.8401 22.2916 26.0052 22.1234 26.3353 22.1234C27.5541 22.1234 28.7729 22.1234 29.9918 22.1234C30.5155 22.1234 30.506 22.1234 30.5631 21.6094C30.7154 20.4194 30.8773 19.22 31.0297 18.03C31.0963 17.4874 31.163 16.9543 31.2296 16.3546C31.0297 16.3546 30.8964 16.3546 30.7535 16.3546C29.2395 16.3546 27.7255 16.3546 26.221 16.3546C25.9068 16.3546 25.8211 16.2594 25.8306 15.9548C25.8497 14.841 25.8306 13.7272 25.8306 12.6134C25.8306 12.404 25.8306 12.185 25.8782 11.9851C26.1068 11.0522 26.8685 10.481 27.9064 10.4715C28.9253 10.462 29.9442 10.462 30.963 10.4715C31.2296 10.4715 31.3344 10.4049 31.3249 10.1193C31.3153 8.63423 31.3153 7.13966 31.3249 5.65461C31.3249 5.38806 31.2487 5.28335 30.963 5.28335C29.4204 5.29287 27.8683 5.26431 26.3258 5.29287C24.4975 5.33095 22.9359 6.01635 21.698 7.38717C20.5554 8.64375 20.0698 10.1478 20.0602 11.8233C20.0602 13.1846 20.0507 14.5459 20.0602 15.8976C20.0602 16.2308 19.9841 16.3451 19.6318 16.3355C18.3748 16.3165 17.1275 16.3355 15.8705 16.3355C15.5468 16.3355 15.3849 16.491 15.3849 16.802C15.3849 18.4203 15.3849 20.0386 15.3849 21.6475C15.3849 21.7712 15.3849 21.9045 15.3849 22.0568C15.5658 22.0663 15.7182 22.0854 15.861 22.0854C17.0894 22.0854 18.3177 22.0854 19.5556 22.0854C19.8857 22.0854 20.0507 22.2535 20.0507 22.5899C20.0507 27.045 20.0507 31.4907 20.0507 35.9458L20.0793 35.9934Z" fill="currentColor"/>
    </svg>
  );

export default FacebookIcon;
