import styled, { css } from "styled-components";

import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../../constants/mediaquerys";

const Default = css`
display: flex;
flex-direction: column;
justify-content: flex-start;
.kk-link{
  padding-top: 48px;
  display: block;
}
`;

const Silver = css`
flex-direction: row;
gap: 48px;

> div {
  width: 50%;
}
`;

const Gold = css``;

const Platinum = css`
gap: 76px;
.kk-link{
  padding-top: 76px;
}
`;

const Diamond = css``;

export const SFooterWrapper = styled.div`
  ${Default};
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;

