import { C_1366_Regular, C_360_Regular, C_768_Regular } from "src/templates/t27/constants/typography";

import styled, { css } from "styled-components";

import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../../constants/mediaquerys";

const Default = css`
display: flex;
align-items: center;
gap: 3px;

> * {
  ${C_360_Regular}
}

> span.separator {
  color: var(--front-2);
}

> a {
  color: var(--primary-100);
  transition: color 0.3s ease-in;

  &:hover {
    color: var(--front-3);
  }
}
`;

const Silver = css`
> * {
  ${C_768_Regular}
}
`;

const Gold = css``;

const Platinum = css`
> * {
  ${C_1366_Regular}
}
`;

const Diamond = css``;

export const SFooterBottomLinks = styled.div`
  ${Default};
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;

