import styled, { css } from "styled-components";

import { MIN_SILVER, MIN_GOLD, MIN_PLATINUM, MIN_DIAMOND } from "../../../../constants/mediaquerys";
import TextStyles from "src/templates/t27/constants/richtextTypography";

const Default = css`
    padding-bottom: 12px;
    ${TextStyles}
`;

const Silver = css``;

const Gold = css``;

const Platinum = css`
    padding-bottom: 24px;
`;

const Diamond = css``;

export const SPlanningDateModalContentHeader = styled.div`
    ${Default};
    ${MIN_SILVER`${Silver}`};
    ${MIN_GOLD`${Gold}`};
    ${MIN_PLATINUM`${Platinum}`};
    ${MIN_DIAMOND`${Diamond}`};
`;
