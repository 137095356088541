import React, { FC, useContext } from "react";
import { Context } from "src/common";
interface IFooterLinkInColumnList {
  title: string;
  link: string;
  isBold: boolean;
}

import { LinksWrapper, Link } from "./Footer.style";

const Navigation: FC = () => {
  const props = useContext(Context);
  const allFooterLinksForUpperWrapper: IFooterLinkInColumnList[] = [];
  const footerNavItems = props.Footer?.Navigationspunkte || [];

  for (const item of props.PrimaryNavigation?.items || []) {
    if (item?.label && item?.link?.[0]?.slug) {
      allFooterLinksForUpperWrapper.push({
        title: item.label || item?.link?.[0]?.nameInNavigation,
        link: item?.link?.[0]?.slug,
        isBold: true
      });
    }

    if (item?.subitems) {
      for (const subItem of item.subitems) {
        if (subItem?.nameInNavigation && subItem?.slug) {
          allFooterLinksForUpperWrapper.push({
            title: subItem?.nameInNavigation,
            link: subItem?.slug,
            isBold: false
          });
        }
      }
    }
  }

  return (
    <LinksWrapper>
      <h4>Links</h4>
      {allFooterLinksForUpperWrapper.map((linkItem) => (
        <Link key={linkItem?.link} isBold={linkItem.isBold} href={`/${linkItem?.link}`}>
          {linkItem?.title}
        </Link>
      ))}
    </LinksWrapper>
  );
}

export default Navigation;