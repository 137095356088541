import React, { FunctionComponent } from "react";
import { FlattenSimpleInterpolation } from "styled-components";

import { SContainerT27 } from "./styles/SContainerT27";
import { SContainerT27Inner } from "./styles/SContainerT27Inner";

const ContainerT27: FunctionComponent<{overwriteStyle?: FlattenSimpleInterpolation}> = ({ children, overwriteStyle }) => 
  (
    <SContainerT27 overwriteStyle={overwriteStyle}>
      <SContainerT27Inner>{children}</SContainerT27Inner>
    </SContainerT27>
  );

export default ContainerT27;
