import React, { Fragment, FunctionComponent, useContext, useState, createContext, FC } from "react";
import { useCallback } from "react";
import { useForm } from "react-hook-form/dist/react-hook-form.ie11";
import { IContext, Context, ILandingEvent } from "src/common";
import { handleFormSubmit } from "src/common/utils/formSubmit";

import * as styles from "./PlanningDateModal.style";
import {
  FormWrapper,
  Separator,
} from "./PlanningDateModal.style";

export const PlanningDateModalContext = createContext<{ setShowModal: Function; showModal: boolean; onShowModal: Function }>(null);

export const PlanningDateModalContextProvider: FC = ({ children }) => {
  const [showModal, setShowModal] = useState(false);
  const onShowModal = useCallback(() => setShowModal(true), [setShowModal]);

  return (
    <PlanningDateModalContext.Provider value={{ showModal, setShowModal, onShowModal }}>
      {children}
    </PlanningDateModalContext.Provider>
  );
};

const PlanningDateModal: FunctionComponent = () =>
{
  const props = useContext(Context);
  const modal = useContext(PlanningDateModalContext);

  const universalTexts = props?.UniversalTexts;
  const planningDateContent = {
    headline: "Jetzt",
    headline1: "Wunschtermin",
    headline2: "sichern!",
    text: "Wählen Sie einen Wunsch-termin für eine persönliche Planungsberatung. Für die Terminbestätigung benötigen wir Ihre Telefonnummer oder E-Mail-Adresse."
  };

  const {
    register, handleSubmit, errors, reset
  } = useForm();

  const [success, setSuccess] = useState(false);

  const onSubmit = (data): void =>
  {
    // Kontaktformular: d-26922068a30343d98a725c3fdaa663da
    // Terminanfrage: d-deb56cdeb9c34309be7a1a521bda38e6
    // Reparaturanfrage: d-82dc22fc5dee48db9e177c0140b37441
    // Produktanfrage: d-ee2129a87d2d4e70bbcf21f1bff6491e
    let title: string | undefined;
    if(typeof window !== "undefined")
    {
      title = document.title;
    }

    handleFormSubmit({
      ...data,
      line: props?.CompanyInformationPrivate?.line,
      subject: `Planungsterminanfrage ${data?.Name ? `von ${data?.Name} ` : ""}${title ? `- ${title}` : ""}`,
      dealerMail: props?.CompanyInformationPublic?.email,
      templateId: "d-deb56cdeb9c34309be7a1a521bda38e6",
      // cc: [{ email: "serviceseite@gedk.de" }]
    }).then(r =>
    {
      reset();
      setSuccess(true);
    });
  };

  const timeOptions: React.ReactElement[] = [];

  for (let i = 6; i < 20; i++) {

    let value;
    let value2;

    if(i < 10)
    {
      value = `0${i}:00`;
      value2 = `0${i}:30`;
    }
    else
    {
      value = `${i}:00`;
      value2 = `${i}:30`;
    }

    timeOptions.push(
      <Fragment key={i}>
        <option value={value}>{value}</option>
        <option value={value2}>{value2}</option>
      </Fragment>
    );
  }

  const vorname = "Vorname";
  const nachname = "Nachname";
  const datum = "Datum";
  const email = universalTexts?.eMail || "E-Mail";
  const telefon = "Telefon";
  const kommentar = "Ammerkung";

  return (
    <FormWrapper>
      <div css={styles.wrapper}>

        <div css={styles.topBar}>

          <h1>{planningDateContent?.headline}</h1>
          <h2>{planningDateContent?.headline1}</h2>
          <h3>{planningDateContent?.headline2}</h3>
          <p>{planningDateContent?.text}</p>
          <span><i className={"fa fa-fw fa-lg fa-phone"} css={styles.sidebarExpandIconSpan}></i>{props?.CompanyInformationPublic?.phone}</span>
          <span><i className={"fa fa-fw fa-lg fa-mail-bulk"} css={styles.sidebarExpandIconSpan}></i>{props?.CompanyInformationPublic?.email}</span>
        </div>

        <div css={styles.contentArea}>
          {success ?
            <form>
              <svg
                css={styles.closeButton}
                onClick={() => modal.setShowModal(false)}
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path d="M23.954 21.03l-9.184-9.095 9.092-9.174-2.832-2.807-9.09 9.179-9.176-9.088-2.81 2.81 9.186 9.105-9.095 9.184 2.81 2.81 9.112-9.192 9.18 9.1z" />
              </svg>
              <h1>DANKE!</h1>
                        Wir werden uns sobald wie möglich bei Ihnen melden.
            </form>
            :<form onSubmit={handleSubmit(onSubmit)}>
              <svg
                css={styles.closeButton}
                onClick={() => modal.setShowModal(false)}
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path d="M23.954 21.03l-9.184-9.095 9.092-9.174-2.832-2.807-9.09 9.179-9.176-9.088-2.81 2.81 9.186 9.105-9.095 9.184 2.81 2.81 9.112-9.192 9.18 9.1z" />
              </svg>
              <h1>Beratungsterminanfrage</h1>
              <div>
                <div css={styles.formRow}>
                  <div css={styles.rowContent}>
                    <input
                      required={true}
                      type="text"
                      placeholder={vorname}
                      id={"vorname"}
                      name="vorname"
                      ref={register}
                    />
										 <div css={styles.sidebarExpandIconContainer}>
                      <i className={"fa fa-fw fa-lg fa-user"} css={styles.sidebarExpandIcon}></i>
                    </div>
                  </div>
                  <div css={styles.rowContent}>
                    <input
                      required={true}
                      type="text"
                      placeholder={nachname}
                      id={"nachname"}
                      name="nachname"
                      ref={register}
                    />
                    <div css={styles.sidebarExpandIconContainer}>
                      <i className={"fa fa-fw fa-lg fa-user"} css={styles.sidebarExpandIcon}></i>
                    </div>
                  </div>
                </div>
                <div css={styles.formRow}>
                  <div css={styles.rowContent}>
                    <input
                      required={true}
                      type="date"
                      placeholder={datum}
                      id={"datum"}
                      name="datum"
                      ref={register}
                    />
                  </div>
                  <div css={styles.rowContent}>
                    <input
                      required={true}
                      type="text"
                      placeholder={telefon}
                      id={"telefon"}
                      name="telefon"
                      ref={register}
                    />
                    <div css={styles.sidebarExpandIconContainer}>
                      <i className={"fa fa-fw fa-lg fa-phone"} css={styles.sidebarExpandIcon}></i>
                    </div>
                  </div>
                </div>
                <div css={styles.formRow}>
                  <div css={styles.doubleRowcontent}>
                    <input
                      required={true}
                      type="email"
                      placeholder={email}
                      id={"email"}
                      name="email"
                      ref={register}
                    />
                    <div css={styles.sidebarExpandIconContainerDouble}>
                      <i className={"fa fa-fw fa-lg fa-mail-bulk"} css={styles.sidebarExpandIcon}></i>
                    </div>
                  </div>

                </div>

                <div css={styles.formRow} style={{ display: "block" }}>
                  <textarea
                    rows={8}
                    required={true}
                    name="kommentar"
                    id={"kommentar"}
                    placeholder={kommentar}
                    ref={register}
                  />
                </div>
              </div>
              <div css={styles.submitWrapper}>

                <div style={{ display: "flex", alignItems: "center" }}>
                  <input
                    required={true}
                    type="checkbox"
                    placeholder="Ich akzeptiere die Datenschutzerklärung"
                    name="checkbox"
                    id="checkbox"
                    ref={register({ required: true })}
                  />
                  <label htmlFor={"checkbox"}>
                                Ich akzeptiere die <a href={`/${props.PageImprint?.slug}`}> Datenschutzerklärung</a>
                  </label>
                </div>
                <button type="submit"><i className={"fa fa-paper-plane"} css={styles.sidebarExpandIconButton}></i>{universalTexts?.Submit || "Absenden"}</button>
              </div>
            </form> }
        </div>
      </div>
    </FormWrapper>

  );
};

export default PlanningDateModal;
