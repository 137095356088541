import styled, { css } from "styled-components";

import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../../../constants/mediaquerys";

const Default = css`
  max-height: 75vh;
  overflow-y: auto;
  padding: 0 24px;
`;

const Silver = css`
padding: 0 28px;
`;

const Gold = css``;

const Platinum = css`
padding: 0 35px;
`;

const Diamond = css``;

export const SModalContent = styled.nav`
  ${Default};
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;

