import styled, { css } from "styled-components";

import { MIN_SILVER, MIN_GOLD, MIN_PLATINUM, MIN_DIAMOND } from "../../../../constants/mediaquerys";

interface IProps {
    hasNoSubPages?: boolean;
    isInBrandArea?: boolean;
}

const Default = css<IProps>`
    padding-left: 24px;

    ${({ hasNoSubPages, isInBrandArea }) =>
        hasNoSubPages &&
   ( isInBrandArea ? css`
            padding-bottom: 76px;
        ` : css`
            padding-bottom: 76px;
        `)}
`;

const Silver = css``;

const Gold = css``;

const Platinum = css<IProps>`
    padding-left: 36px;

    ${({ hasNoSubPages, isInBrandArea }) =>
        hasNoSubPages &&
    (isInBrandArea ? css`
            padding-bottom: 116px;
        ` : css`
            padding-bottom: 226px;
        `)}
`;

const Diamond = css``;

export const SHeaderPlaceholderWrapper = styled.div`
    ${Default};
    ${MIN_SILVER`${Silver}`};
    ${MIN_GOLD`${Gold}`};
    ${MIN_PLATINUM`${Platinum}`};
    ${MIN_DIAMOND`${Diamond}`};
`;
