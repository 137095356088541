import { C_1366_Bold, C_360_Bold, C_768_Bold, H4_1366, H4_360, H4_768 } from "src/templates/t27/constants/typography";

import styled, { css } from "styled-components";

import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../../constants/mediaquerys";

const Default = css`
  > h4 {
    ${H4_360}
    color: var(--primary-100);
  }

  > p {
    ${C_360_Bold}
    color: var(--front-2);
  }
`;

const Silver = css`
  > h4 {
    ${H4_768}
  }

  > p {
    ${C_768_Bold}
  }
`;

const Gold = css``;

const Platinum = css`
  > h4 {
    ${H4_1366}
  }

  > p {
    ${C_1366_Bold}
  }
`;

const Diamond = css``;

export const SFooterAddress = styled.div`
  ${Default};
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
