import { Context, LogoBannerHorizontal } from "src/common";
import { CopyrightNotice } from "src/common/components/CopyrightNotice/CopyrightNotice";
import { LineLogo } from "src/common/components/LineLogo";
import { IGenFooter, IGenFooterSectionHeadline, IGenFooter_Navigationspunkte } from "src/common/types_dealer_gen";
import ContainerT27 from "src/templates/t27/BaseComponents/container/ContainerT27";

import Link from "next/link";
import React, { Fragment, FunctionComponent, useContext } from "react";
import { css } from "styled-components";

import { SFooter } from "./styles/SFooter";
import { SFooterAddress } from "./styles/SFooterAddress";
import { SFooterBottomLinks } from "./styles/SFooterBottomLinks";
import { SFooterBottomLinksWrapper } from "./styles/SFooterBottomLinksWrapper";
import { SFooterContact } from "./styles/SFooterContact";
import { SFooterContactWrapper } from "./styles/SFooterContactWrapper";
import { SFooterCustomHtml } from "./styles/SFooterCustomHtml";
import { SFooterLeftSideWrapper } from "./styles/SFooterLeftSideWrapper";
import { SFooterLinkGroup } from "./styles/SFooterLinkGroup";
import { SFooterLinksWrapper } from "./styles/SFooterLinksWrapper";
import { SFooterLogoBannerWrapper } from "./styles/SFooterLogoBannerWrapper";
import { SFooterLogoWrapper } from "./styles/SFooterLogoWrapper";
import { SFooterWrapper } from "./styles/SFooterWrapper";
import SectionSeparator from "src/templates/t27/BaseComponents/section-separator/SeparatorGreyLine";
import { hexIsLight, rgba2hex } from "../../../../../common/utils/colorHelper";
import { getContactCompanyNamePrefix } from "../../../../../common/components/ImprintStart";
import { GoogleStars } from "../../../../../common/components/GoogleStars/GoogleStars";

type TFooterLinkGroup = IGenFooterSectionHeadline & {
    links: IGenFooter_Navigationspunkte[];
};

export const FooterT27: FunctionComponent = () => {
    const props = useContext(Context);

    const palette = props?.Settings;
    const showInKitchenCompass = !!props.CompanyInformationPrivate?.showInKitchenCompass;

    const footer = props?.Footer as IGenFooter;
    const companyInformation = props?.CompanyInformationPublic;
    const footerLinks = footer?.Navigationspunkte;
    const footerLinksSections: any[] = [];
    const isBottomLink = (item: any): boolean => (item?.slug as string)?.includes("imprint-datenschutz");
    const footerBottomLinks = footerLinks.filter((item) => isBottomLink(item));

    footerLinks.forEach((item, index, array) => {
        const nextItemExists = array[index + 1];
        const isSectionItemAHeadline = item.__typename === "FooterSectionHeadline";
        const isNextItemALink = nextItemExists && nextItemExists.__typename !== "FooterSectionHeadline";
        const isItemBottomLink = isBottomLink(item);
        const currentFooterSection = footerLinksSections[footerLinksSections.length - 1];

        if (isSectionItemAHeadline && nextItemExists && isNextItemALink) {
            footerLinksSections.push({
                ...item,
                links: [] as IGenFooter_Navigationspunkte[],
            });
        } else if (
            footerLinksSections.length > 0 &&
            !isItemBottomLink &&
            currentFooterSection &&
            currentFooterSection.links
        ) {
            currentFooterSection.links.push(item);
        }
    });

    if (footer.Navigationspunkte.length < 1) {
        return null;
    }

    let style: any = {};
    if (palette.themeBackgroundColor) {
        try {
            let hex = palette.themeBackgroundColor;
            if (hex.indexOf("rgb") > -1) {
                hex = rgba2hex(palette.themeBackgroundColor);
            }

            if (!hexIsLight(hex)) {
                style = {
                    filter: "brightness(3)",
                };
            }
        } catch (err) {
            console.log(` themeBackgroundColor err`, err);
        }
    }

    return (
        <ContainerT27
            overwriteStyle={css`
                padding-bottom: 0 !important;
                position: relative;
                background-color: var(--back-1);
            `}
        >
            <SFooter>
                {props.AllBrand?.length > 0 ? (
                    <SFooterLogoBannerWrapper>
                        <LogoBannerHorizontal />
                    </SFooterLogoBannerWrapper>
                ) : (
                    <SectionSeparator
                        hasContainerPaddingWrapper={false}
                        stylesOverwrite={css`
                            margin: 0 !important;
                        `}
                    />
                )}
                <SFooterWrapper>
                    <SFooterLeftSideWrapper>
                        <SFooterLogoWrapper>
                            <LineLogo />
                        </SFooterLogoWrapper>
                        <GoogleStars
                            overwriteStyle={css`
                                div {
                                    margin-top: 1rem;
                                }
                                svg {
                                    width: 20px;
                                    height: 20px;
                                }
                            `}
                        />
                        {footer?.customHtml && (
                            <SFooterCustomHtml
                                id="customHtmlFooter"
                                dangerouslySetInnerHTML={{ __html: footer.customHtml }}
                            />
                        )}
                        <SFooterContactWrapper>
                            {companyInformation &&
                                (companyInformation.companyName ||
                                    companyInformation.street ||
                                    companyInformation.postcode ||
                                    companyInformation.place) && (
                                    <SFooterAddress>
                                        {(companyInformation?.companyNameOverwriteContact ||
                                            companyInformation?.companyName) && (
                                            <h4>{getContactCompanyNamePrefix()}</h4>
                                        )}
                                        {companyInformation.street && <p>{companyInformation.street}</p>}
                                        {(companyInformation.postcode || companyInformation.place) && (
                                            <p>
                                                {companyInformation.postcode}&nbsp;
                                                {companyInformation.place}
                                            </p>
                                        )}
                                    </SFooterAddress>
                                )}
                            {companyInformation?.phone && (
                                <SFooterContact>
                                    <h4>Telefon:</h4>&nbsp;
                                    <a href={`tel:${companyInformation.phone}`}>{companyInformation.phone}</a>
                                </SFooterContact>
                            )}
                            {companyInformation?.email && (
                                <SFooterContact>
                                    <h4>Mail:</h4>&nbsp;
                                    <a href={`mailto:${companyInformation.email}`}>{companyInformation.email}</a>
                                </SFooterContact>
                            )}
                        </SFooterContactWrapper>
                    </SFooterLeftSideWrapper>
                    <div>
                        {showInKitchenCompass ? (
                            <a
                                href="https://www.kuechenkompass.com"
                                target="_blank"
                                className="kk-link"
                                rel="noopener noreferrer"
                                style={{ cursor: "pointer" }}
                            >
                                <img style={{ ...style }} src="/images/kk.png" alt="empfohlen von Küchenkompass" />
                            </a>
                        ) : null}
                        {footerLinksSections.length > 0 && (
                            <SFooterLinksWrapper>
                                {footerLinksSections.map((item, index) => (
                                    <SFooterLinkGroup key={index}>
                                        <h4>{item?.headline}</h4>
                                        <ul>
                                            {item?.links?.map(
                                                (link, linkIndex) =>
                                                    (link?.nameInNavigation ||
                                                        link?.slug ||
                                                        link?.externalLinkAddress) && (
                                                        <li key={`link-${linkIndex}`}>
                                                            {link?.externalLinkAddress ? (
                                                                <a
                                                                    target="_blank"
                                                                    href={
                                                                        "https://" +
                                                                        `${link?.externalLinkAddress}`
                                                                            .replace("http:", "https:")
                                                                            .replace("https://", "")
                                                                    }
                                                                    rel="noreferrer"
                                                                >
                                                                    {link?.externalLinkName || ""}
                                                                </a>
                                                            ) : (
                                                                <Link href={`/${link?.slug}`} passHref>
                                                                    {link?.nameInNavigation || link?.slug}
                                                                </Link>
                                                            )}
                                                        </li>
                                                    )
                                            )}
                                        </ul>
                                    </SFooterLinkGroup>
                                ))}
                            </SFooterLinksWrapper>
                        )}
                    </div>
                </SFooterWrapper>
                <SFooterBottomLinksWrapper>
                    {footerBottomLinks?.length > 0 && (
                        <SFooterBottomLinks>
                            {footerBottomLinks.map(
                                (item: any, index) =>
                                    (item?.nameInNavigation || item?.slug) && (
                                        <Fragment key={`link-${index}`}>
                                            <Link href={`/${item?.slug}`} passHref>
                                                <a>{item?.nameInNavigation || item?.slug}</a>
                                            </Link>
                                            {index + 1 < footerBottomLinks.length && (
                                                <span className="separator">&nbsp;|&nbsp;</span>
                                            )}
                                        </Fragment>
                                    )
                            )}
                        </SFooterBottomLinks>
                    )}
                    <p className="copyright-notice">
                        <CopyrightNotice />
                    </p>
                </SFooterBottomLinksWrapper>
            </SFooter>
        </ContainerT27>
    );
};
