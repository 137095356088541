import styled, { FlattenSimpleInterpolation, css } from "styled-components";

import { MIN_SILVER, MIN_GOLD, MIN_PLATINUM, MIN_DIAMOND } from "../../../constants/mediaquerys";

const Default = css`
`;

const Silver = css``;

const Gold = css``;

const Platinum = css``;

const Diamond = css``;

const SLink = styled.a<{ overwriteStyle: FlattenSimpleInterpolation }>`
    ${Default}
    ${MIN_SILVER`${Silver}`};
    ${MIN_GOLD`${Gold}`};
    ${MIN_PLATINUM`${Platinum}`};
    ${MIN_DIAMOND`${Diamond}`};
    ${({ overwriteStyle }) => (overwriteStyle ? overwriteStyle : "")};
`;
export default SLink;
