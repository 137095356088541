import { C_1366_Regular, C_768_Regular } from "src/templates/t27/constants/typography";

import styled, { css } from "styled-components";

import { MIN_SILVER, MIN_GOLD, MIN_PLATINUM, MIN_DIAMOND } from "../../../../../constants/mediaquerys";

interface IProps {
    isFixedBackground: boolean;
}

const Default = css`
    display: none;
`;

const Silver = css`
    display: flex;
    align-items: center;
    color: var(--front-2);
    ${C_768_Regular}
    ${({ isFixedBackground }: IProps) =>
        !isFixedBackground &&
        css`
            margin-left: calc(50% + 12px);
        `}

    span {
        font-weight: 700;
        color: var(--primary-100);
        cursor: pointer;
    }
`;

const Gold = css``;

const Platinum = css`
    ${C_1366_Regular}
    ${({ isFixedBackground }: IProps) =>
        !isFixedBackground &&
        css`
            margin-left: calc(50% + 19px);
        `}
`;

const Diamond = css``;

export const SBreadCrumb = styled.div<IProps>`
    ${Default};
    ${MIN_SILVER`${Silver}`};
    ${MIN_GOLD`${Gold}`};
    ${MIN_PLATINUM`${Platinum}`};
    ${MIN_DIAMOND`${Diamond}`};
`;
