import { C_1366_Regular, C_360_Regular, C_768_Regular, H4_1366, H4_360, H4_768 } from "src/templates/t27/constants/typography";

import styled, { css } from "styled-components";

import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../../constants/mediaquerys";
import { CSSWithOpacityHoverAnimation } from "src/templates/t27/constants/globalStyle";

const Default = css`
display: flex;
align-items: center;

  > h4 {
    ${H4_360}
    color: var(--primary-100);
  }

  > a {
    ${C_360_Regular}
    color: var(--front-2);
    
    ${CSSWithOpacityHoverAnimation}
  }
`;

const Silver = css`
  > h4 {
    ${H4_768}
  }

  > a {
    ${C_768_Regular}
  }
`;

const Gold = css``;

const Platinum = css`
  > h4 {
    ${H4_1366}
  }

  > a {
    ${C_1366_Regular}
  }
`;

const Diamond = css``;

export const SFooterContact = styled.div`
  ${Default};
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;

