import { C_1366_Regular, C_360_Regular, C_768_Regular } from "src/templates/t27/constants/typography";

import styled, { css } from "styled-components";

import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../../constants/mediaquerys";

const Default = css`
padding-top: 12px;
display: flex;
align-items: flex-start;
flex-direction: column;
gap: 40px;

> p.copyright-notice {
  color: var(--front-2);
  ${C_360_Regular}
}
`;

const Silver = css`
padding-top: 0;
justify-content: space-between;
align-items: center;
gap: 0;
flex-direction: row;

> p.copyright-notice {
  ${C_768_Regular}
}
`;

const Gold = css``;

const Platinum = css`
> p.copyright-notice {
  ${C_1366_Regular}
}
`;

const Diamond = css``;

export const SFooterBottomLinksWrapper = styled.div`
  ${Default};
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;

