import React, { FC, useEffect, useState } from "react";
import { INavigationItem_Link, debounce } from "src/common";
import { SBreadCrumb } from "./styles/SBreadCrumb";
import Link from "next/link";

interface IBreadCrumbProps {
    isFixedBackground: boolean;
    currentNavItemLink: INavigationItem_Link;
}

export const BreadCrumb: FC<IBreadCrumbProps> = ({ currentNavItemLink, isFixedBackground }) => {
    return (
        <SBreadCrumb isFixedBackground={isFixedBackground}>
            <Link href="/">Home</Link>&nbsp;{">"}&nbsp;
            <span>
                <Link href={`/${currentNavItemLink.slug}`}>
                    {currentNavItemLink.nameInNavigation || currentNavItemLink.slug}
                </Link>
            </span>
        </SBreadCrumb>
    );
};
