import styled, { css } from "styled-components";

import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../../../constants/mediaquerys";

const Default = css`
border: none;
color: var(--back-1);
display: flex;
justify-content: center;
align-items: center;
transition: color 0.3s ease-in;
margin-left: auto;

&:hover {
  color: var(--front-3);
}

&,svg {
  width: 25px;
  height: 25px;
}
`;

const Silver = css``;

const Gold = css``;

const Platinum = css`
&, svg {
  width: 30px;
  height: 30px;
}
`;

const Diamond = css``;

export const SModalButton = styled.button`
  ${Default};
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
