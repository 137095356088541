export default {
  black: "#000000",
  white: "#ffffff",
  textColor: "#666666",
  textColorHeadings: "#444444",
  textColorLight: "#adadad;",
  textColorInverted: "#aaaaaa",
  black50: "#505050",
  highlight: "#ffffff",
  white80: "rgba(255,255,255, 0.8)",
  primary: "#113ceb",
  secondary: "#f6931e",
  trans50: "rgba(255,255,255, 0.5)",
  bg: "#113ceb",
  bgInverted: "#6b6b6b",
  bgLight: "#f5f5f5",
  bgProduct: "#cc5d61",
};
