import { Context, getPage } from "src/common";
import SocialIcons from "src/common/BaseComponents/social-icons/SocialIcons";
import CloseIcon from "src/templates/t27/icons/CloseIcon";

import Link from "next/link";
import React, { Dispatch, FunctionComponent, SetStateAction, useContext, useEffect } from "react";
import { createPortal } from "react-dom";

import { SModal } from "./styles/SModal";
import { SModalButton } from "./styles/SModalButton";
import { SModalContent } from "./styles/SModalContent";
import { SModalContentWrapper } from "./styles/SModalContentWrapper";
import { SModalHeader } from "./styles/SModalHeader";
import { SModalLinks } from "./styles/SModalLinks";
import { SModalSocialBar } from "./styles/SModalSocialBar";
import { SModalWrapper } from "./styles/SModalWrapper";

interface IProps {
    setIsMenuOpen: Dispatch<SetStateAction<boolean>>;
    isMenuOpen: boolean;
}

const Modal: FunctionComponent<React.PropsWithChildren<IProps>> = ({ setIsMenuOpen, isMenuOpen }) => {
    const props = useContext(Context);

    const currentPage = getPage(props);

    useEffect(() => {
        if (typeof window !== "undefined") {
            if (isMenuOpen) {
                document.body.style.overflow = "hidden";
            }

            const handleEscape = (e: KeyboardEvent): void => {
                if (e.key === "Escape") {
                    setIsMenuOpen(false);
                }
            };

            document.addEventListener("keydown", handleEscape);

            return () => {
                document.removeEventListener("keydown", handleEscape);
                document.body.style.overflow = "";
            };
        }
    }, [isMenuOpen, setIsMenuOpen]);

    return createPortal(
        <SModal>
            <SModalWrapper>
                <SModalContentWrapper isOpen={isMenuOpen}>
                    <SModalContent>
                        <SModalHeader>
                            {(currentPage?.nameInNavigation || currentPage?.slug) && (
                                <h4>{currentPage.nameInNavigation || currentPage?.slug}</h4>
                            )}
                            <SModalButton onClick={() => setIsMenuOpen(false)}>
                                <CloseIcon />
                            </SModalButton>
                        </SModalHeader>
                        <SModalLinks>
                            {props?.PrimaryNavigation?.items.map((item, index) => {
                                const itemLink = item?.link?.[0];
                                const itemExternalLink = (item as any)?.externalLink?.[0];
                                const externalLinkAddress = itemExternalLink?.externalLinkAddress;
                                const externalLinkName = itemExternalLink?.externalLinkName;

                                return (
                                    (itemLink?.nameInNavigation ||
                                        itemLink?.slug ||
                                        (externalLinkAddress && externalLinkName)) && (
                                        <li key={index} className="parent-link">
                                            {externalLinkAddress && externalLinkName ? (
                                                <a href={externalLinkAddress} target="_blank" rel="noreferrer">
                                                    <h2>{externalLinkName}</h2>
                                                </a>
                                            ) : (
                                                <Link href={`/${itemLink.slug}`} passHref>
                                                    <a>
                                                        <h2>{itemLink?.nameInNavigation || itemLink?.slug}</h2>
                                                    </a>
                                                </Link>
                                            )}
                                            {item.subitems && item.subitems.length > 0 && (
                                                <ul className="sub-links">
                                                    {item.subitems.map((subitem: any, subIndex) => {
                                                        const externalLinkAddress = subitem?.externalLinkAddress;
                                                        const externalLinkName = subitem?.externalLinkName;

                                                        return (
                                                            ((subitem?.nameInNavigation ||
                                                                subitem?.slug ||
                                                                (externalLinkAddress && externalLinkName)) && (
                                                                <li key={`sub-${subIndex}`} className="sub-link">
                                                                    {externalLinkAddress && externalLinkName ? (
                                                                        <a
                                                                            href={externalLinkAddress}
                                                                            target="_blank"
                                                                            rel="noreferrer"
                                                                        >
                                                                            <h4>{externalLinkName}</h4>
                                                                        </a>
                                                                    ) : (
                                                                        <Link href={`/${subitem.slug}`} passHref>
                                                                            <a>
                                                                                <h4>
                                                                                    {subitem?.nameInNavigation ||
                                                                                        subitem?.slug}
                                                                                </h4>
                                                                            </a>
                                                                        </Link>
                                                                    )}
                                                                </li>
                                                            )) ||
                                                            null
                                                        );
                                                    })}
                                                </ul>
                                            )}
                                        </li>
                                    )
                                );
                            })}
                        </SModalLinks>
                    </SModalContent>
                    <SModalSocialBar>
                        <SocialIcons />
                    </SModalSocialBar>
                </SModalContentWrapper>
            </SModalWrapper>
        </SModal>,
        document.body
    );
};

export default Modal;
