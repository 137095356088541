import React, { FC } from "react";
import { ICompanyInformationPublic } from "src/common";
import RichTextGeneral from "../RichText";
import { OpeningHoursWrapper } from "./Footer.style";
const OpeningHours: FC<{ companyInformation: ICompanyInformationPublic }> = ({ companyInformation }) => (
  <OpeningHoursWrapper>
    <h4>{companyInformation?.companyName || companyInformation?.companyNameOverwriteContact }</h4>
    <p>
      Blub Willkommen im Küchenstudio Kilian Lipp Testküchen in Berlin. Die beste Adresse in Sachen Küche. Als Partner der Küchengilde sind wir eines von mehreren erstklassigen, inhabergeführten Küchenhäusern mit modernster Ausstattung. Wir arbeiten streng nach unserem selbst auferlegten Kodex in bewährter Handwerkstradition und
    </p>
  </OpeningHoursWrapper>
);

export default OpeningHours;