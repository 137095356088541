import { AffixWrapper } from "src/common/components/Affix";
import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";
import styled from "styled-components";

import { Container } from "../Grid";

const WIDTH = "100vw";
export const AffixContainer: any = styled.div`
  ${AffixWrapper} {
    position: absolute;
    width: 100vw;

    @media (${BREAKPOINTS_DM.gold_1024}){
      position: fixed;
    }
  }
`;

export const BurguerIconWrapper: any = styled.div<{ open?: boolean }>`
  position: absolute;
  top: 28px;
  right: 20px;
  cursor: pointer;
  z-index: 999;
  width: 28px;
  height: 28px;

  ${({ open, theme }) => !open
    ? `
      > span {
        display: block;
        height: 5px;
        width: 24px;
        border-radius: 0;
        background-color: ${theme.palette.textColor};

        &:not(first-child) {
          margin-top: 4px;
        }
      }
    `
    : `

    > span {
      position: absolute;
      top: 12px;
      left: 0;
      display: block;
      height: 5px;
      width: 30px;
      border-radius: 0;
      background: ${theme.palette.textColor};

      &:nth-child(1) {
        transform: rotate(45deg)
      }
      &:nth-child(2) {
        display: none;
      }
      &:nth-child(3) {
        transform: rotate(-45deg)
      }
    }
  `}


  @media (${BREAKPOINTS_DM.gold_1024}){
    display: none;
  }
`;

export const LogoLink = styled.a`
  height: 50px;
  @media (${BREAKPOINTS_DM.silver_768}) {
    padding: 10px 8px 5px;
    line-height: 37px;
  }
`;

export const Logo = styled.img`
  display: block;
  height: 50px;
  @media (${BREAKPOINTS_DM.silver_768}) {
    transform: scale(1.2);
    margin-left: 0px;
    margin-top: 0px;
    height: 64px;
    transition: all .2s ease-in-out;
  }
`;

export const SubNavigationLinks: any = styled.div<{ open: boolean }>`
  display: ${({ open }) => open ? "block" : "none"};
  left: 0;
  background-color: white;
  min-width: 140px;
  > a {
    display: flex;
    width: 100%;
    padding: 8px 20px;
    font-family: Roboto, sans-serif;
    font-size: 13px;
    color: ${({ theme }) => theme.palette.textColor};
    font-weight: normal;
    line-height: 18px;
    text-shadow: none;

    :hover {
      color: ${({ theme }) => theme.palette.primary};
    }

    @media (${BREAKPOINTS_DM.silver_768}) {
      border-bottom: 1px solid #ccc;
    }
  }

  @media (${BREAKPOINTS_DM.silver_768}) {
    display: none;
    margin-top: 30px;
    padding-bottom: 5px;
    position: absolute;
    border-top: solid 2px ${({ theme }) => theme.palette.primary};
    z-index: 99;
    box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
  }
`;

export const NavigationLink: any = styled.li<{ active: boolean }>`
  display: block;
  border-top: 1px solid #ccc;
  line-height: 36px;
  transition: all .5s;

  :last-child {
    border-right: none;
  }

  :first-child {
    border-top: none;
  }

  width: 100%;

  > a {
    font-family: Roboto, sans-serif;
    display: inline;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    width: 100%;
  }

  > svg {
    background-color: white;
    margin-left: 10px;
  }

  @media (${BREAKPOINTS_DM.silver_768}) {
    position: relative;
    border-top: none;
    width: unset;
    :hover {
      ${SubNavigationLinks} {
        display: block;
      }

      :after {
        border-color: ${({ theme }) => theme.palette.primary};
      }
    }

    > button, > svg {
      display: none;
    }

    :after {
      position: absolute;
      display: block;
      content: '';
      width: 30px;
      margin-left: -15px;
      bottom: 8px;
      left: 50%;
      border-bottom: solid 2px ${({ theme, active }) => active ? theme.palette.primary : "transparent" };
    }
  }
`;

export const Icon: any = styled.img`

`;

export const NavigationLinks: any = styled.div<{ isMenuActive: boolean }>`
  background-color: ${({ theme }) => theme.palette.white};
  display: flex;
  width: 100%;
  max-width: 1400px;
  ul {
    display: block;
    align-items: center;
    width: 100%;
    justify-content: flex-start;
    list-style: none;
    overflow-y: auto;
    margin: 0;

    @media (${BREAKPOINTS_DM.silver_768}) {
      overflow-y: unset;
    }
  }

  @media (${BREAKPOINTS_DM.silver_768}) {
    background-color: transparent;

    ${NavigationLink} {
      display: flex;
    }

    ul {
      display: flex;
      flex-wrap: wrap;
    }
  }
`;

export const NavigationContainer: any = styled.div<{ active: boolean }>`
  display: flex;
  flex: 1;

  ${({ active }) => active
    ? `
      ${NavigationLinks} {
        display: flex;
        margin-top: 10px;
      }
    `
    : `
      ${NavigationLinks} {
        display: none;
      }
    `
  }

  @media (${BREAKPOINTS_DM.silver_768}) {
    ${NavigationLinks} {
      display: flex;
      margin-top: 0;
    }
  }
`;

export const HaderContainer = styled.div<{ affix?: boolean }>`
  position: relative;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: ${({ theme }) => theme.palette.white};
  width: 100vw;
  z-index: 999;
  font-size: 12px;
  padding: 15px 10px;

  @media (${BREAKPOINTS_DM.silver_768}) {
    background-color: ${({ theme, affix }) => affix ? theme.palette.white : "transparent"};
    transition: all .2s;
    padding: 0;
    position: relative;
    width: ${WIDTH};
    ${({ affix }) => affix && `
      box-shadow: 0 0 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
    `}

    ${Logo} {
      ${({ affix }) => affix && `
        height: 50px;
      `}
    }
    ${NavigationLink} {
      color: white;
      text-shadow: 0 0 4px #030303;
      padding: 35px 20px 20px;

      ${({ affix }) => affix && `
        color: #6e6e6e !important;
        text-shadow: none;
        padding: 25px 15px;
      `}
    }
  }

  > a {
    align-self: flex-start;
  }

  ${Container} {
    flex-direction: column;
    padding: 0;
  }
`;

export const HeaderBannerDropDown = styled.div<{ flex?: boolean }>`
  height: 100%;
  position: relative;
  background-color: #1a1a1a;

  > a {
    font-size: 11px;
  }

  > div {
    font-family: Roboto, sans-serif;
    position: absolute;
    display: none;
    min-width: 200px;
    top: 42px;
    right: 0;

    z-index: 9999999;
    padding: 30px;
    background-color: #1a1a1a;
    border-top: 2px solid ${({ theme }) => theme.palette.primary};

    // content
    table {
      width: 100%;
    }
    p {
      font-family: inherit;
      text-align: center;
      line-height: 18px;
      margin: 0;
    }

    a {
      color: ${({ theme }) => theme.palette.primary};
      text-decoration: underline;
      :hover {
        color: #2550ff;
      }
    }

    img {
      margin-right: 20px;
    }

    address {
      min-width: 180px;
      padding-right: 40px;
      line-height: 18px;
      font-style: normal;
    }
  }

  :hover {
    > div {
      display: ${({ flex }) => flex ? "flex" : "block"};
    }

    > a {
      color: ${({ theme }) => theme.palette.primary};
    }
  }
`;

export const HeaderBanner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;

  height: fit-content;
  background-color: #1a1a1a;
  font-size: 13px;
  color: #a8a8a8;
  
  .left-side {
    display: flex;
    flex-direction: row;
  }

  ${Container} {
    height: 100%;
    padding: 0;
    justify-content: space-between;

    > div {
      height: 100%;
      display: flex;
    }
  }

  a {
    display: inline-flex;
    align-items: center;
    height: 100%;
    margin-right: 10px;
    margin-left: 10px;

    cursor: pointer;
    transition: color .3s;

    svg {
      margin-right: 5px;
    }

    :hover {
      color: ${({ theme }) => theme.palette.primary};
    }
  }

  @media (${BREAKPOINTS_DM.silver_768}) {
    flex-direction: row;
    height: 42px;
    justify-content: space-between;
  }
`;
