import styled, { css } from "styled-components";

import { MIN_SILVER, MIN_GOLD, MIN_PLATINUM, MIN_DIAMOND } from "../../../../constants/mediaquerys";
import { CSSWithOpacityHoverAnimation } from "src/templates/t27/constants/globalStyle";

const Default = css`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &,
    > svg {
        width: 18px;
        height: 21px;
    }

    ${CSSWithOpacityHoverAnimation}
`;

const Silver = css``;

const Gold = css``;

const Platinum = css`
    &,
    > svg {
        width: 24px;
        height: 20px;
    }
`;

const Diamond = css``;

export const SPlanningDateModalCloseIconWrapper = styled.span`
    ${Default};
    ${MIN_SILVER`${Silver}`};
    ${MIN_GOLD`${Gold}`};
    ${MIN_PLATINUM`${Platinum}`};
    ${MIN_DIAMOND`${Diamond}`};
`;
