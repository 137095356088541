import styled, { css } from "styled-components";

import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../../constants/mediaquerys";

const Default = css`
padding: 48px 0;
display: flex;
align-content: flex-start;
flex-wrap: wrap;  

> div {
  width: 50%;

  // select all divs but not last 2
  &:not(:nth-last-child(-n+2)) {
    padding-bottom: 37px;
  }

  // select odd divs
  &:nth-child(odd) {
    padding-right: 24px;
  }
}
`;

const Silver = css``;

const Gold = css``;

const Platinum = css`
padding: 76px 0;

> div {
  &:not(:nth-last-child(-n+2)) {
    padding-bottom: 48px;
  }

  &:nth-child(odd) {
    padding-right: 40px;
  }
}
`;

const Diamond = css``;

export const SFooterLinksWrapper = styled.div`
  ${Default};
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;

