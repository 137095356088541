import styled, { css, keyframes } from "styled-components";

import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../../../constants/mediaquerys";

interface SModalContentWrapperProps 
{
  isOpen: boolean;
}

const openAnimation = keyframes`
  0% {
    transform: translateY(-200px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;

const Default = css<SModalContentWrapperProps>`
color: var(--back-1);
background-color: var(--primary-100);
width: 360px;
margin-left: auto;

${({ isOpen }) => isOpen && css`
  animation: ${openAnimation} 0.3s ease-in;
`}
`;

const Silver = css`
`;

const Gold = css``;

const Platinum = css`
width: 450px;
`;

const Diamond = css``;

export const SModalContentWrapper = styled.div`
  ${Default};
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;

