import React, { FunctionComponent } from "react";

const PhoneIcon: FunctionComponent<{size?: number}> = ({ size= 20 }) => 
  (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 35 36" fill="none">
      <path d="M34.4734 16.6884C34.5342 6.87405 26.3364 -0.306903 18.5034 0.0632487V2.55913C22.2223 2.64374 25.4345 3.98686 28.059 6.72599C30.6836 9.46511 31.9604 12.8176 32.0516 16.6884H34.4734ZM28.3124 16.6884C28.4036 10.8823 23.5295 6.27123 18.5135 6.47217V8.97862C22.658 9.08438 25.8601 12.5004 25.9209 16.6989H28.3225L28.3124 16.6884Z" fill="currentColor"/>
      <path d="M0 8.1963C1.59092 5.43602 3.55678 3.0036 5.79624 0.803836C6.00903 0.592321 6.25223 0.412532 6.49543 0.24332C6.9717 -0.0951046 7.31623 -0.0739531 7.75196 0.327926C7.95462 0.507714 8.14716 0.71923 8.29915 0.941321C9.86981 3.25742 11.329 5.64754 12.4943 8.21745C13.1226 9.60288 13.0618 9.84612 11.9573 10.872C11.2175 11.5594 10.4879 12.2468 9.73808 12.9237C9.55568 13.0823 9.53541 13.2515 9.60635 13.4842C10.6197 17.0694 12.5551 20.02 15.2709 22.4207C17.1354 24.06 19.2127 25.2973 21.5839 25.9742C21.8068 26.0376 21.9689 26.0271 22.1209 25.8367C22.7391 25.107 23.3673 24.3772 23.9855 23.6475C25.0596 22.3573 25.2825 22.2832 26.7721 23.0341C29.1433 24.2292 31.3726 25.6781 33.5108 27.275C34.747 28.2057 34.7977 28.7344 33.7844 29.8978C31.8894 32.0658 29.731 33.906 27.4004 35.5347C27.1572 35.7039 26.9039 35.8519 26.6505 36H25.9615C25.6372 35.9366 25.3129 35.8625 24.9887 35.7991C22.3337 35.2385 19.8815 34.1281 17.5711 32.6898C12.6261 29.6228 8.51195 25.604 5.12744 20.7709C3.15145 17.9577 1.54026 14.9436 0.597863 11.57C0.354665 10.7028 0.202666 9.80382 0.0101333 8.92603C0.0101333 8.68279 0.0101333 8.43954 0.0101333 8.20688L0 8.1963Z" fill="currentColor"/>
      <path d="M34.4734 16.6884H32.0516C31.9705 12.8176 30.6836 9.46511 28.059 6.72599C25.4345 3.98686 22.2223 2.64374 18.5034 2.55913V0.0632487C26.3364 -0.306903 34.5342 6.87405 34.4734 16.6989V16.6884Z" fill="currentColor"/>
      <path d="M28.3124 16.6884H25.9108C25.85 12.4898 22.658 9.07381 18.5034 8.96805V6.46159C23.5193 6.26065 28.4036 10.8717 28.3022 16.6778L28.3124 16.6884Z" fill="currentColor"/>
    </svg>
  );

export default PhoneIcon;
