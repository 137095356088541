import { C_1366_Regular, C_360_Regular, C_768_Regular } from "src/templates/t27/constants/typography";

import styled, { css } from "styled-components";

import { MIN_SILVER, MIN_GOLD, MIN_PLATINUM, MIN_DIAMOND } from "../../../../../constants/mediaquerys";
import { CSSWithOpacityHoverAnimation } from "src/templates/t27/constants/globalStyle";

interface SSubPageListItemProps {
    isActive: boolean;
}

const Default = css<SSubPageListItemProps>`
    pointer-events: all;
    border-top: 1px solid var(--front-3);
    padding: 4px 0 4px 15px;
    color: var(${({ isActive }) => (isActive ? "--primary-100" : "--front-2")});
    background-color: var(--back-1);
    ${C_360_Regular}

    &:last-child {
        padding-bottom: 5px;
    }

    > a {
        ${CSSWithOpacityHoverAnimation}
    }
`;

const Silver = css`
    ${C_768_Regular}
`;

const Gold = css``;

const Platinum = css`
    ${C_1366_Regular}
    padding-left: 24px;
`;

const Diamond = css``;

export const SSubPageListItem = styled.li`
    ${Default};
    ${MIN_SILVER`${Silver}`};
    ${MIN_GOLD`${Gold}`};
    ${MIN_PLATINUM`${Platinum}`};
    ${MIN_DIAMOND`${Diamond}`};
`;
