import React, { FC, useCallback, useState } from "react";
import { getCurrentNavItem, getHeaderNavItemProps, INavigationItem, INavigationItem_Subitems } from "src/common";

import { NavigationContainer, NavigationLinks, NavigationLink, SubNavigationLinks, Icon, BurguerIconWrapper } from './Header.styles';

export const BurguerIcon: FC<{ open: boolean; onClick: (boolean) => void }> = ({ open, onClick }) => (
  <BurguerIconWrapper open={open} onClick={onClick}>
    <span />
    <span />
    <span />
  </BurguerIconWrapper>
);

const SubNavitation: FC<{ subitems: INavigationItem_Subitems[]; slug: string; open: boolean }> = ({ subitems, slug, open }) => (
  <>
    {subitems && !!subitems.length && (
      <SubNavigationLinks open={open}>
        {subitems.map((subItem) => (
          <a key={subItem.slug} href={`/${subItem.slug}`} data-active={slug === subItem.slug}>
            {subItem.nameInNavigation}
          </a>
        ))}
      </SubNavigationLinks>
    )}
  </>
);

const Navigation: FC<{ items: INavigationItem[]; slug: string; isMenuActive: boolean; }> = ({ items, slug, isMenuActive }) => {
  const [currentLinkItem, toggleShowSubItems] = useState(slug);
  const onToggleShowSubItems = (link: string): void => toggleShowSubItems(
    link === currentLinkItem
      ? null
      : link
  );

  const currentMainNavItem: any = getCurrentNavItem(items, slug);
  return (
    <NavigationContainer active={isMenuActive}>
      <NavigationLinks>
        <ul>
          <NavigationLink>
            <a href="/">Home</a>
          </NavigationLink>
          {items.map((item, index) => {
            const externalLink = (item.link?.[0] as any)?.__typename === "ExternalLink";
            const itemProps = getHeaderNavItemProps(item);
            if (!itemProps) return;

            const active =
              !!slug && (`${slug}` === `${itemProps.link}` || currentMainNavItem?.link?.[0]?.slug === `${itemProps.link}`);

            const subItemsOpen = currentLinkItem === itemProps.link;
            const otherProps = {
              target: externalLink && "_BLANK",
            };

            return (
              <NavigationLink key={`${index}-${subItemsOpen}`} active={active}>
                <a href={`${externalLink ? "" : "/"}${itemProps.link}`} {...otherProps}>
                  {item.icon && item.icon[0] && (
                    <Icon
                      src={item.icon?.[0]?.src}
                      alt={"hoverIcon"}
                    />
                  )}

                  {itemProps.title}
                </a>

                {!!item.subitems?.length && <button onClick={() => onToggleShowSubItems(itemProps.link)}><i className={`fa fa-fw fa-chevron-${subItemsOpen ? "down" : "right"}`} /></button>}

                <SubNavitation subitems={item.subitems} slug={slug} open={subItemsOpen}/>
              </NavigationLink>
            );
          })}
        </ul>
      </NavigationLinks>
    </NavigationContainer>
  );
};

export default Navigation;