import styled, { css } from "styled-components";

import { MIN_SILVER, MIN_GOLD, MIN_PLATINUM, MIN_DIAMOND } from "../../../../../constants/mediaquerys";

const Default = css`
    color: var(--special-logobg);
    background-color: var(--special-logocolor);
    padding: 12px 24px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 16px;
    transition: color 0.3s ease-in;

    > * {
        display: flex;
        align-items: center;
        justify-content: center;
        transition: color 0.3s ease-in;

        &:hover {
            opacity: 0.7;
        }

        &,
        svg {
            width: 24px;
            height: 24px;
        }
    }
`;

const Silver = css`
    padding: 12px 28px;
    gap: 24px;
`;

const Gold = css``;

const Platinum = css`
    padding: 20px 35px;
    gap: 39px;

    > * {
        &,
        svg {
            width: 35px;
            height: 35px;
        }
    }
`;

const Diamond = css``;

export const SModalSocialBar = styled.div`
    ${Default};
    ${MIN_SILVER`${Silver}`};
    ${MIN_GOLD`${Gold}`};
    ${MIN_PLATINUM`${Platinum}`};
    ${MIN_DIAMOND`${Diamond}`};
`;
