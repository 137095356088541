import { Context, getCurrentNavItem, getPage } from "src/common";
import { useRouter } from "next/router";
import React, { FunctionComponent, useContext, useEffect, useMemo, useRef, useState } from "react";

import { BurgerMenu } from "./burgerMenu/BurgerMenu";
import { Logo } from "./logo/Logo";
import Modal from "./modal/Modal";
import { SHeader } from "./styles/SHeader";
import { SHeaderMainNavWrapper } from "./styles/SHeaderMainNavWrapper";
import { SubPageList } from "./sub-page-list/SubPageList";
import { getPageLayout } from "src/common/utils/getPageLayout";
import { IGenAsset, IGenPage } from "src/common/types_dealer_gen";
import { SHeaderFixedBackgroundWrapper } from "./styles/SHeaderFixedBackgroundWrapper";
import { SHeaderContentWrapper } from "./styles/SHeaderContentWrapper";
import { SHeaderStickyContainer } from "./styles/SHeaderStickyContainer";
import { SBronzeWrapper } from "src/common/constants/SBronzeWrapper";
import { SSliverWrapper } from "src/common/constants/SSliverWrapper";
import ContainerT27 from "src/templates/t27/BaseComponents/container/ContainerT27";
import { css } from "styled-components";
import { SSubPageFixedBackgroundWrapper } from "./styles/SSubPageFixedBackgroundWrapper";
import { SHeaderLogoWrapper } from "./styles/SHeaderLogoWrapper";
import { BreadCrumb } from "./BreadCrumb/BreadCrumb";
import { useShouldScale } from "src/templates/t27/hooks/useShouldScaleLogo";
import { SHeaderPlaceholder } from "./styles/SHeaderPlaceholder";
import { SHeaderPlaceholderWrapper } from "./styles/SHeaderPlaceholderWrapper";

const Header: FunctionComponent = () => {
    const props = useContext(Context);
    const currentPage = getPage(props);
    const layout = useMemo(() => getPageLayout(currentPage as IGenPage), [currentPage]);
    const router = useRouter();
    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
    const { shouldScale } = useShouldScale();

    useEffect(() => {
        if (typeof window !== "undefined" && isMenuOpen) {
            setIsMenuOpen(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [router.asPath]);

    if (!props?.PrimaryNavigation?.items) {
        return null;
    }

    const slug = props?.slug;
    const isInBrandArea =
        props?.PageBrandOverview?.slug === slug ||
        props?.PageBrand?.slug === slug ||
        props?.PageBrandTopic?.slug === slug;

    const isLandingPage = slug == null;
    const currentNavItem = getCurrentNavItem(props.PrimaryNavigation.items, slug);
    const currentNavItemLink = currentNavItem?.link?.[0];
    const hasBreadCrumb = !isLandingPage && (!!currentNavItemLink?.nameInNavigation || !!currentNavItemLink?.slug);
    const hasSubPages = currentNavItem?.subitems?.length > 0;
    const isFixedBackground =
        currentPage?.imageFixedBackground || layout === "fixedonecol" || isLandingPage || isLandingPage;

    const { width: logoWidth, height: logoHeight } = (props?.CompanyInformationPublic?.logo?.[0] || {}) as IGenAsset;
    const logoAspectRatio = logoWidth && logoHeight && logoWidth / logoHeight;

    return (
        <>
            <SHeaderStickyContainer>
                <SHeaderFixedBackgroundWrapper>
                    <SHeader>
                        <SHeaderContentWrapper>
                            <SHeaderMainNavWrapper>
                                <SHeaderLogoWrapper
                                    shouldScaleLogo={shouldScale}
                                    isFixedBackground={isFixedBackground && !isInBrandArea}
                                >
                                    <Logo isFixedBackground={isFixedBackground && !isInBrandArea} />
                                </SHeaderLogoWrapper>
                                {isFixedBackground && !isInBrandArea && (
                                    <SHeaderPlaceholderWrapper>
                                        <SHeaderPlaceholder />
                                    </SHeaderPlaceholderWrapper>
                                )}
                                {hasBreadCrumb && (
                                    <BreadCrumb
                                        isFixedBackground={isFixedBackground && !isInBrandArea}
                                        currentNavItemLink={currentNavItemLink}
                                    />
                                )}
                                <BurgerMenu
                                    onClick={() => setIsMenuOpen(true)}
                                    isFixedBackground={isFixedBackground && !isInBrandArea}
                                />
                            </SHeaderMainNavWrapper>
                        </SHeaderContentWrapper>
                        {isMenuOpen && <Modal setIsMenuOpen={setIsMenuOpen} isMenuOpen={isMenuOpen} />}
                    </SHeader>
                </SHeaderFixedBackgroundWrapper>
            </SHeaderStickyContainer>
            {hasSubPages && !isInBrandArea && (
                <SSubPageFixedBackgroundWrapper isFixedBackground={isFixedBackground}>
                    <SSliverWrapper>
                        <ContainerT27
                            overwriteStyle={css`
                                padding-bottom: 0 !important;
                            `}
                        >
                            <SubPageList />
                        </ContainerT27>
                    </SSliverWrapper>
                </SSubPageFixedBackgroundWrapper>
            )}
            {(!isFixedBackground || isInBrandArea) && (
                <>
                    {!isLandingPage && (
                        <SHeaderPlaceholderWrapper
                            hasNoSubPages={!hasSubPages || isInBrandArea}
                            isInBrandArea={isInBrandArea}
                        >
                            <SHeaderPlaceholder
                                logoAspectRatio={logoAspectRatio}
                                hasSubPages={hasSubPages && !isInBrandArea}
                            />
                        </SHeaderPlaceholderWrapper>
                    )}
                    <SBronzeWrapper>
                        {hasSubPages && !isInBrandArea && (
                            <ContainerT27>
                                <nav>
                                    <SubPageList />
                                </nav>
                            </ContainerT27>
                        )}
                    </SBronzeWrapper>
                </>
            )}
        </>
    );
};

export default Header;
