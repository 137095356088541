import { Context, Img } from "src/common";

import React, { FunctionComponent, useContext } from "react";

import LogoLink from "../../LogoLink";

export const Logo: FunctionComponent<{ isFixedBackground?: boolean }> = ({ isFixedBackground }) => {
    const props = useContext(Context);
    return (
        <LogoLink href={"/"} isFixedBackground={isFixedBackground}>
            <Img
                lazyload={false}
                contain
                quality={500}
                src={props?.CompanyInformationPublic?.logo?.[0]?.src || null}
                alt="logo"
            />
        </LogoLink>
    );
};
