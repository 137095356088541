import styled, { css } from "styled-components";

import { MIN_SILVER, MIN_GOLD, MIN_PLATINUM, MIN_DIAMOND } from "../../../../constants/mediaquerys";

interface IProps {
}

const Default = css<IProps>`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;


    position: relative;
    max-width: 1366px;
    margin: 0 auto;
    pointer-events: all;
`;

const Silver = css<IProps>`
`;

const Gold = css``;

const Platinum = css``;

const Diamond = css``;

export const SHeaderMainNavWrapper = styled.nav<IProps>`
    ${Default};
    ${MIN_SILVER`${Silver}`};
    ${MIN_GOLD`${Gold}`};
    ${MIN_PLATINUM`${Platinum}`};
    ${MIN_DIAMOND`${Diamond}`};
`;
