import React, { FunctionComponent } from "react";

const EmailIcon: FunctionComponent<{size?: number}> = ({ size= 20 }) => 
  (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 49 36" fill="none">
      <path d="M44.727 0H0.486328V35.9934H48.4775V0H44.727ZM3.33356 4.75257C7.86168 9.17854 12.3441 13.5482 16.8036 17.8953C12.2983 22.3213 7.82737 26.7248 3.33356 31.1507V4.75257ZM18.919 19.9676C18.919 19.9676 19.0905 20.1252 19.2163 20.2378C20.5999 21.5893 21.9835 22.9407 23.3785 24.3034C24.2247 25.1256 24.9565 25.1256 25.8026 24.2922C27.1405 22.9745 28.4783 21.6681 29.8162 20.3505C29.9305 20.2378 30.0335 20.0914 30.1249 19.9788C34.6531 24.4386 39.1011 28.8195 43.5492 33.1892H5.4947C9.94278 28.8082 14.4137 24.4048 18.919 19.9676ZM45.6303 31.1282C41.1708 26.736 36.7227 22.3664 32.3204 18.0192C36.757 13.6608 41.2051 9.2799 45.6303 4.9215V31.1282ZM43.4806 2.78172C37.1801 8.98709 30.8453 15.215 24.4762 21.4767C18.1643 15.3163 11.7723 9.08845 5.32318 2.78172H43.4806Z" fill="currentColor"/>
    </svg>
  );

export default EmailIcon;
