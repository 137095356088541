import styled, { css } from "styled-components";

import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../../constants/mediaquerys";

const Default = css`
border-bottom: 1px solid var(--back-3);
`;

const Silver = css`
border-bottom: none;
`;

const Gold = css``;

const Platinum = css``;

const Diamond = css``;

export const SFooterLeftSideWrapper = styled.div`
  ${Default};
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;

