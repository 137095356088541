import styled, { css } from "styled-components";

import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../../constants/mediaquerys";

const Default = css`
padding: 48px 0;
> div {
  background-color: var(--special-logobg);
  margin: 0;
  padding: 30px 0;
}
border-top: 1px solid var(--back-3);
border-bottom: 1px solid var(--back-3);
`;

const Silver = css``;

const Gold = css``;

const Platinum = css`
padding: 76px 0;
`;

const Diamond = css``;

export const SFooterLogoBannerWrapper = styled.div`
  ${Default};
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;

