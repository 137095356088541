import styled, { css } from "styled-components";

import { MIN_SILVER, MIN_GOLD, MIN_PLATINUM, MIN_DIAMOND } from "../../../../constants/mediaquerys";

interface IProps {
    logoAspectRatio?: number;
    hasSubPages?: boolean;
}

const Default = css<IProps>`
    width: calc(66.6vw - 24px);
    max-height: 100px;

    ${({ logoAspectRatio }) =>
        logoAspectRatio
            ? css`
                  aspect-ratio: ${logoAspectRatio};
              `
            : css`
                  height: 1px;
              `}

    ${({ hasSubPages }) =>
        hasSubPages &&
        css`
            aspect-ratio: unset;
            max-height: unset;
            height: 168px;
        `}
`;

const Silver = css<IProps>`
    width: 41.6vw;
    max-height: 120px;

    ${({ hasSubPages }) =>
        hasSubPages &&
        css`
            aspect-ratio: unset;
            max-height: unset;
            height: 1px;
        `}
`;

const Gold = css`
    max-height: 150px;
`;

const Platinum = css`
    width: 39vw;
    max-height: 175px;
    max-width: 600px;
`;

const Diamond = css``;

export const SHeaderPlaceholder = styled.div`
    ${Default};
    ${MIN_SILVER`${Silver}`};
    ${MIN_GOLD`${Gold}`};
    ${MIN_PLATINUM`${Platinum}`};
    ${MIN_DIAMOND`${Diamond}`};
`;
