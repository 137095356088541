import styled, { css } from "styled-components";

import { MIN_SILVER, MIN_GOLD, MIN_PLATINUM, MIN_DIAMOND } from "../../../../constants/mediaquerys";

const Default = css`
    -moz-appearance: none; /* Firefox */
    -webkit-appearance: none; /* Safari and Chrome */
    appearance: none;

    padding: 12px;
`;

const Silver = css`
    padding: 10.5px 22px;
`;

const Gold = css``;

const Platinum = css`
    padding: 21px 38px;
`;

const Diamond = css``;

export const SPlanningDateModalSelect = styled.select`
    ${Default};
    ${MIN_SILVER`${Silver}`};
    ${MIN_GOLD`${Gold}`};
    ${MIN_PLATINUM`${Platinum}`};
    ${MIN_DIAMOND`${Diamond}`};
`;
