import { Context, getCurrentNavItem } from "src/common";

import Link from "next/link";
import React, { FunctionComponent, useContext } from "react";

import { SSubPageList } from "./styles/SSubPageList";
import { SSubPageListItem } from "./styles/SSubPageListItem";

export const SubPageList: FunctionComponent = () => {
    const props = useContext(Context);
    const slug = props?.slug;
    const currentNavSubItems = getCurrentNavItem(props.PrimaryNavigation.items, slug)?.subitems;
    // ticket 86bxzr3j4 below
    const isInBrandArea =
        props?.PageBrandOverview?.slug === slug ||
        props?.PageBrand?.slug === slug ||
        props?.PageBrandTopic?.slug === slug;

    return (
        <>
            {!isInBrandArea && currentNavSubItems && currentNavSubItems.length > 0 && (
                <SSubPageList className="sub-page-list">
                    {currentNavSubItems.map(
                        (item, index) =>
                            (item.nameInNavigation || item.slug) && (
                                <SSubPageListItem key={index} isActive={item.slug === slug}>
                                    <Link href={`/${item.slug}`} passHref>
                                        <a>{item.nameInNavigation || item.slug}</a>
                                    </Link>
                                </SSubPageListItem>
                            )
                    )}
                </SSubPageList>
            )}
        </>
    );
};
