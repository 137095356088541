import React, { FunctionComponent } from "react";

import { SLogoLinkWrapper } from "./SLogoLinkWrapper";

interface ILogoLink {
    href?: string;
    isFixedBackground?: boolean;
}

const LogoLink: FunctionComponent<ILogoLink> = ({ children, href, isFixedBackground }) => {
    return (
        <SLogoLinkWrapper
            /** using this id to get the container width */
            id="header-logo-link"
            isFixedBackground={isFixedBackground}
            href={`${href ? href : "/"}`}
        >
            {children}
        </SLogoLinkWrapper>
    );
};

export default LogoLink;
