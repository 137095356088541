// @ts-nocheck
import styled, { css } from "styled-components";

import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";
import { Container } from "../Grid";

export const FooterWrapper = styled.div`
  background-color: #2a2a2a;
  color: #aaa;
  font-weight: 300;
  font-size: 13px;
  width: 100%;
  padding: 10px 0;
  margin: auto;

  ${Container} {
    flex-direction: column;
  }

  @media (${BREAKPOINTS_DM.silver_768}) {
    padding: 20px 0 32px 0;
    ${Container} {
      flex-direction: row;
    }
  }

  h4 {
    color: #aaa;
    margin-top: 20px;
    margin-bottom: 30px;
    font-weight: 300;
    text-transform: none;
    font-size: 16px;
    letter-spacing: 1px;
    text-align: center;
    :after {
      left: calc(50% - 15px);
    }
    @media (${BREAKPOINTS_DM.silver_768}) {
      text-align: left;
      margin-top: 0px;
      :after {
        left: 0;

      }
    }

  }
`;

export const LinksWrapper: any = styled.div`
  width: 100%;
  padding: 10px 15px;
  text-align: center;

  a {
    font-size: 13px;
    &:hover {
      opacity: .7;
    }
  }

  @media (${BREAKPOINTS_DM.silver_768}) {
    width: calc(100% / 12 * 3);
    text-align: left;
  }
`;

export const Link: any = styled.a`
  font-family: "Roboto Slab", sans-serif;
  display: block;
  line-height: 18px;
  padding-bottom: 10px;
`;

export const AddressWrapper: any = styled.div`
  width: 100%;
  text-align: center;

  @media (${BREAKPOINTS_DM.silver_768}) {
    width: calc(100% / 12 * 3);
    text-align: left;
  }

  padding: 10px 15px;
  p, div, a {
    font-size: 13px;
    line-height: 18px;
  }

  a {
    &:hover {
      opacity: .7;
    }
  }

  & > .managingDirectorWrapper {
    display: flex;
    align-items: center;
    div {
      margin-top: 0;
    }
  }

  & > .addressLinksWrapper {
    & > span {
      margin: 0 4px;
    }
  }
`;

export const OpeningHoursWrapper: any = styled.div`
  width: 100%;
  text-align: center;
  @media (${BREAKPOINTS_DM.silver_768}) {
    width: calc(100% / 12 * 6);
    text-align: left;
  }

  padding: 10px 15px;

  div {
    font-size: 13px;
    line-height: 18px;
  }
`;

export const Bottom: any = styled.div`
  width: 100%;
  background-color: #222;
  font-size: 11px;
  font-weight: 400;
  color: #aaa;

  span, a {
    display: inline-flex;
    margin: 10px;
  }

  a {
    color: ${({ theme }) => theme.palette.primary};
  }

  ${Container} {
    flex-direction: column;
    @media (${BREAKPOINTS_DM.silver_768}) {
      flex-direction: row;
    }
  }
`;

