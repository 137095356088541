import React, { FunctionComponent, HTMLAttributes } from "react";

import { SMenu, Line } from "./BurgerMenu.style";
import { useShouldScale } from "src/templates/t27/hooks/useShouldScaleLogo";

type IBurgerMenuProps = HTMLAttributes<HTMLDivElement> & {
    isFixedBackground?: boolean;
};

export const BurgerMenu: FunctionComponent<IBurgerMenuProps> = ({ isFixedBackground, ...props }) => {
    const { shouldScale } = useShouldScale();

    return (
        <SMenu {...props} shouldScale={shouldScale} isFixedBackground={isFixedBackground}>
            <Line />
        </SMenu>
    );
};
