import { IGenPage, IGenPage_Layout } from "../types_dealer_gen";

export const getPageLayout = (page: IGenPage): string => {
    const fixedSelectItems = [
        { key: "default", value: "Default" },
        { key: "twocol", value: "Zweispaltig (nur T3)" },
        { key: "fixedonecol", value: "Einspaltig mit fixiertem Bild (nur T3,T27)" },
        {
            key: "subnavsmall",
            value: "Übersicht mit Subnavigationsgalerie klein (nur T3)",
        },
        { key: "subnavbig", value: "Übersicht mit Subnavigationsgalerie groß (nur T3)" },
        { key: "fixedtwocol", value: "Zweispaltig mit fixiertem Bild (nur T3)" },
        { key: "subnavhidden", value: "Einspaltig ohne Subnavigationsgalerie" },
        {
            key: "subnavsmallfixedheader",
            value: "Übersicht mit Subnavigationsgalerie klein mit fixiertem Bild (nur T3)",
        },
    ];

    const layout = fixedSelectItems.find((item) => item.key === page?.layout || item.value === page?.layout)?.key;

    switch (true) {
        // for this 4 options we route back to the diffrent old flag combinations
        case layout === "twocol":
            page.imageFixedBackground = false;
            page.twoColumnLayout = true;
            break;
        case layout === "fixedonecol":
            page.imageFixedBackground = true;
            page.twoColumnLayout = false;
            break;
        case layout === "fixedtwocol":
            page.imageFixedBackground = true;
            page.twoColumnLayout = true;
            break;
        case layout === "subnavsmallfixedheader":
            page.imageFixedBackground = true;
            break;
        case layout === null || layout === undefined:
            // if layout is null we do nothing and keep the existing values for imageFixedBackground and twoColumnLayout
            // to support old data in this fields => non breaking change
            // and we do nothing
            break;
        default:
            // for the 4 new cases "default", "subnavhidden", "subnavsmall" and "subnavbig"  we just disable the old options
            page.imageFixedBackground = false;
            page.twoColumnLayout = false;
    }

    return layout as IGenPage_Layout;
};
