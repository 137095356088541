import styled, { FlattenInterpolation, ThemeProps, css } from "styled-components";

import { MIN_SILVER, MIN_GOLD, MIN_PLATINUM, MIN_DIAMOND } from "../../../../constants/mediaquerys";
import { TModalHeight } from "../PlanningDateModal";

interface IProps {
    isExpanded?: boolean;
    positionTimeoutDurationWithMs: number;
    modalHeight: TModalHeight;
}

type IStyle = FlattenInterpolation<ThemeProps<IProps>>;

const Default = ({ isExpanded, positionTimeoutDurationWithMs, modalHeight: { bronzeHeight } }: IProps): IStyle => css`
    position: fixed;
    bottom: 0px;
    width: calc(100% - 48px); // 48px - padding left and right
    left: 24px; // 48 / 2
    transform: translateY(100%);
    transition: all ${positionTimeoutDurationWithMs}ms;
    bottom: 0;
    background-color: var(--back-1);
    overflow-y: auto;
    padding: 24px;
    max-height: 80vh;
    overflow-y: auto;

    ${isExpanded &&
    css`
        transform: none;
        bottom: ${bronzeHeight ?? 80}px;
    `}
`;

const Silver = ({ isExpanded, modalHeight: { silverHeight } }: IProps): IStyle => css`
    ${isExpanded &&
    css`
        bottom: ${silverHeight ?? 60}px;
    `}
`;

const Gold = css``;

const Platinum = ({ isExpanded, modalHeight: { platinumHeight } }: IProps): IStyle => css`
    width: 700px;
    left: calc(50% - 360px); // 588 / 2
    padding: 30px 32px;

    ${isExpanded &&
    css`
        bottom: ${platinumHeight ?? 120}px;
    `}
`;

const Diamond = css``;

export const SPlanningDateModalContentArea = styled.div`
    ${Default};
    ${MIN_SILVER`${Silver}`};
    ${MIN_GOLD`${Gold}`};
    ${MIN_PLATINUM`${Platinum}`};
    ${MIN_DIAMOND`${Diamond}`};
`;
