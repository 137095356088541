import styled, { FlattenSimpleInterpolation, css } from "styled-components";

import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../constants/mediaquerys";

const Default = css`
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 48px;
`;

const Silver = css``;

const Gold = css``;

const Platinum = css`
  padding-left: 76px;
  padding-right: 76px;
  padding-bottom: 76px;
`;

const Diamond = css``;

export const SContainerT27 = styled.div<{
  overwriteStyle?: FlattenSimpleInterpolation;
}>`
  ${Default};
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
  ${({ overwriteStyle }) => (overwriteStyle ? overwriteStyle : "")};
`;
