import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";

import styled, { FlattenSimpleInterpolation } from "styled-components";

export const SSectionSeparator = styled.hr<{stylesOverwrite: FlattenSimpleInterpolation}>`
  border: none;
  height: 1px;
  background-color: var(--back-3);
  margin-bottom: 48px;
  width: 100%;

  @media (${BREAKPOINTS_DM.platinum_1366}) {
    margin-bottom: 76px;
  }

  ${({stylesOverwrite}) => stylesOverwrite ? stylesOverwrite : ""}
`;
