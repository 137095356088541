import React, { FunctionComponent } from "react";

const MapPinIcon: FunctionComponent<{size?: number}> = ({ size= 20 }) => 
  (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 26 36" fill="none">
      <path d="M13.4526 35.9934H13.0064C12.8124 35.8014 12.5699 35.6459 12.434 35.4264C8.98072 29.5556 5.5177 23.6847 2.10318 17.7955C0.143705 14.3937 -0.0697024 10.8364 1.55996 7.3066C3.38362 3.3561 6.62354 0.969347 11.1342 0.182906C13.2586 -0.18288 15.3539 0.00915778 17.4006 0.676718C19.622 1.40829 21.4845 2.63368 22.988 4.32544C24.4528 5.98977 25.3937 7.88271 25.7526 10.0317C25.8496 10.5987 25.8981 11.1656 25.966 11.7326V12.3636C25.9466 12.4916 25.9175 12.6105 25.9078 12.7385C25.7914 14.5583 25.2482 16.2592 24.317 17.8504C20.9025 23.6847 17.4782 29.519 14.054 35.3533C13.9085 35.591 13.6563 35.7739 13.4526 35.9843V35.9934ZM13.2198 5.99891C9.70825 6.0172 6.87575 8.70573 6.88545 11.9978C6.88545 15.3173 9.75675 18.015 13.2683 17.9876C16.7701 17.9693 19.6026 15.2625 19.5832 11.9704C19.5638 8.66001 16.7022 5.97148 13.2198 5.98977V5.99891Z" fill="currentColor"/>
    </svg>
  );

export default MapPinIcon;
