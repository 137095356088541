import styled, { css } from "styled-components";

import { MIN_SILVER, MIN_GOLD, MIN_PLATINUM, MIN_DIAMOND } from "../../../constants/mediaquerys";

interface IProps {
    isFixedBackground: boolean;
}

const Default = css<IProps>`
    ${({ isFixedBackground }) =>
        !isFixedBackground &&
        css`
            padding: 24px;
        `}
    display: block;

    img {
        object-fit: contain;
        object-position: top left;
        width: calc(66.6vw - 24px);
        max-height: 100px;
    }
`;

const Silver = css<IProps>`
    padding: 24px;
    img {
        width: 41.6vw;
        max-height: 120px;
    }
`;

const Gold = css`
    img {
        max-height: 150px;
    }
`;

const Platinum = css<IProps>`
    padding: 36px 36px 36px 76px;
    border: 1px transparent;
    img {
        width: 39vw;
        max-height: 175px;
        max-width: 600px;
    }
`;

const Diamond = css``;

export const SLogoLinkWrapper = styled.a<IProps>`
    ${Default};
    ${MIN_SILVER`${Silver}`};
    ${MIN_GOLD`${Gold}`};
    ${MIN_PLATINUM`${Platinum}`};
    ${MIN_DIAMOND`${Diamond}`};
`;
